import Vue from 'vue'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const calendar = require('dayjs/plugin/calendar')
const advancedFormat = require('dayjs/plugin/advancedFormat')

dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

Vue.filter('diffForHumans', date => {
  if (!date) { return null }
  return dayjs.utc(date).local().fromNow()
})

Vue.filter('diffForHumansMaxTwoWeeks', date => {
  if (!date) { return null }

  const twoWeeksAgo = dayjs().subtract(2, 'week')

  if (dayjs(date).isBefore(twoWeeksAgo)) {
    // If the date is more than two weeks ago, format it with the specified format
    return dayjs.utc(date).local().format('D MMMM YYYY [at] HH:mm')
  }
  // If the date is within the last two weeks, use fromNow() to get a relative time
  return dayjs(date).fromNow()
})

Vue.filter('informalDate', date => {
  if (!date) { return null }
  const parsedTimeStamp = dayjs.utc(date).local()

  if (!parsedTimeStamp.isValid()) {
    return date
  }

  return parsedTimeStamp.format('D MMMM YYYY [at] HH:mm')
})

Vue.filter('formatDate', date => {
  if (!date) { return null }
  return dayjs.utc(date).local().format('DD-MMM-YYYY hh:mma z')
})

Vue.filter('shortDate', date => {
  if (!date) { return null }
  return dayjs.utc(date).local().format('DD MMM, YYYY')
})

Vue.filter('calendarTime', date => {
  if (!date) { return null }
  const dt = dayjs.utc(date).local()
  return dayjs().calendar(dt)
})
