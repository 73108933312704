<template>
  <b-dropdown
    :variant="variant"
    :size="size"
    class="tipTapDropdown"
    toggle-class="btn btn-icon"
    :class="classes"
    :disabled="disabled"
    no-caret
  >
    <template #button-content>
      <slot name="label" />
    </template>

    <slot name="items" />
  </b-dropdown>
</template>

<script>
export default {
  name: 'TipTapDropdown',
  props: {
    variant: {
      type: String,
      default: 'flat-secondary',
    },
    size: {
      type: String,
      default: 'sm',
    },
    classes: {
      type: String,
      default: 'btn-icon',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/@core/scss/base/colors.scss';

.is-active {
  color: $primary;
}

</style>

<style lang="scss">
.tipTapDropdown {
  line-height: 0.5rem;
}

.tipTapDropdown :hover {
  color: black;
}

.dark-layout {
  .tipTapDropdown :hover {
    color: white;
  }
}
</style>
