export default modelId => [
  {
    headerKey: 3,
    header: 'Workspace',
  },
  {
    title: 'Dashboard',
    route: 'model_dashboard',
    icon: 'GridIcon',
  },
  {
    title: 'Collections',
    route: {
      name: 'collections',
      params: {
        modelId,
      },
    },
    iconLibrary: 'font-awesome',
    icon: ['fas', 'folder'],
  },
  {
    title: 'Specifications',
    route: {
      name: 'all_specifications_table',
      params: {
        modelId,
      },
    },
    iconLibrary: 'font-awesome',
    icon: ['fas', 'clipboard-list'],
  },
  {
    title: 'Behaviour Trees',
    route: 'behaviour_trees',
    iconLibrary: 'font-awesome',
    icon: ['fas', 'vector-square'],
  },
  {
    headerKey: 4,
    header: 'Workflows',
  },
  {
    title: 'Modelling',
    route: 'functional-analysis',
    iconLibrary: 'font-awesome',
    icon: 'cogs',
    children: [
      {
        title: 'Func. Analysis',
        route: 'domain_analysis',
      },
      {
        title: 'System Decomp.',
        route: 'domain_system',
      },
      {
        title: 'Behaviour Modelling',
        route: 'behaviour_trees_modelling',
      },
    ],
  },
  {
    headerKey: 5,
    header: 'Tools',
  },
  {
    title: 'Issues',
    route: 'app_issues',
    iconLibrary: 'font-awesome',
    icon: ['fas', 'spinner'],
  },
  {
    title: 'Domain Model',
    route: 'domain-model',
    iconLibrary: 'font-awesome',
    icon: 'bezier-curve',
    children: [
      {
        title: 'Ontology',
        route: 'domain_ontology',
      },
      {
        title: 'Ecosystem',
        route: 'domain_ecosystem',
      },
      {
        title: 'Interfaces',
        route: 'interfaces',
      },
      {
        title: 'Configurations',
        route: 'model_configurations',
      },
      {
        title: 'Class Diagrams',
        route: 'class_diagrams',
      },
    ],
  },
  {
    title: 'Tests',
    route: 'requirements-analysis',
    iconLibrary: 'font-awesome',
    icon: ['fas', 'vials'],
    children: [
      {
        title: 'Test Cases',
        route: 'model_tests',
      },
      {
        title: 'Evidence',
        route: {
          name: 'model_evidence',
          params: {
            modelId,
          },
        },
      },
    ],
  },
  {
    title: 'Reviews',
    route: 'specification_reviews',
    iconLibrary: 'font-awesome',
    icon: ['fas', 'clipboard-check'],
    // tag: 'New',
    // tagVariant: 'success',
  },
  {
    title: 'Releases',
    route: 'model_releases',
    iconLibrary: 'font-awesome',
    icon: ['fas', 'paper-plane'],
  },
  {
    title: 'Canvas',
    route: 'model_canvas',
    icon: ['fas', 'columns'],
    iconLibrary: 'font-awesome',
  },
  {
    title: 'Ideas',
    route: 'model_ideas',
    icon: ['far', 'lightbulb'],
    iconLibrary: 'font-awesome',
  },
  {
    title: 'Trade Studies',
    route: {
      name: 'trade_study',
    },
    iconLibrary: 'font-awesome',
    icon: ['fas', 'scale-unbalanced'],
  },
]
