import coreService from '@/libs/api-services/core-service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
import router from '@/router'

const getDefaultState = () => ({
  recordsList: [],
  selectedRecord: null,
  selectedRecordETag: '',
  methodOptions: [],
  phaseOptions: [],
  resultOptions: [],
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    SET_RECORDS_LIST: (state, data) => { state.recordsList = data },
  },
  actions: {
    getRecordList: ({ commit }, reqId) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'model-id': modelId }, params: { with_evidence: true } }
      return coreService
        .get(`/v1/qualification_records/in_requirement/${reqId}`, config)
        .then(({ data }) => { commit('SET_RECORDS_LIST', data) })
        .catch(error => {
          console.error(error)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to fetch list of Qualification Records',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    getRecord: ({ commit }, recordId) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'model-id': modelId } }
      return coreService
        .get(`/v1/qualification_records/${recordId}`, config)
        .then(response => response)
        .catch(error => {
          console.error(error)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to fetch Qualification Record',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    createQualificationRecord: ({ commit }, data) => {
      const { modelId } = router.currentRoute.params
      const payload = {
        method: data.method,
        phase: data.phase,
        statement: data.statement,
        record_result: data.record_result,
        parent_node_id: data.parent_node_id,
      }

      return coreService.qualificationRecordsApi
        .createQualificationRecord(modelId, payload)
        .then(response => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Qualification Record created',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          return response
        })
        .catch(error => {
          console.error(error)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create Qualification Record',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    updateQualificationRecord: ({ state }, data) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'model-id': modelId, 'If-Match': data.etag } }
      const payload = {
        method: data.method,
        phase: data.phase,
        statement: data.statement,
        record_result: data.record_result,
      }

      return coreService
        .patch(`/v1/qualification_records/${data.id}`, payload, config)
        .then(response => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Qualification Record updated',
              icon: 'Edit2Icon',
              variant: 'success',
            },
          })
          return response.data
        })
        .catch(error => {
          console.error(error)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to update Qualification Record',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    deleteQualificationRecord: ({ state }, data) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'model-id': modelId, 'If-Match': data.etag } }

      return coreService
        .delete(`/v1/qualification_records/${data.id}`, config)
        .then(response => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Qualification Record deleted',
              icon: 'TrashIcon',
              variant: 'success',
            },
          })
          return true
        })
        .catch(error => {
          console.error(error)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to delete Qualification Record',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
        })
    },
    saveEvidenceLinkWithRecord: ({ state, commit }, payload) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'model-id': modelId, 'If-Match': payload.etag } }
      const data = {
        evidence_id: payload.evidenceId,
        assessed: payload.assessed,
        assessed_by: payload.assessedBy,
      }
      return coreService
        .post(`/v1/qualification_records/${payload.qrId}/link`, data, config)
        .then(({ data }) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Evidence link saved',
              icon: 'Edit2Icon',
              variant: 'success',
            },
          })
          return data
        })
        .catch(error => {
          console.error(error)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to save evidence link',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
        })
    },
    removeEvidenceLinkWithRecord: ({ state, commit }, payload) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'model-id': modelId } }

      return coreService
        .delete(`/v1/qualification_records/${payload.qrId}/link/${payload.evidenceId}`, config)
        .then(() => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Unlinked Evidence with Qualification Record',
              icon: 'Edit2Icon',
              variant: 'success',
            },
          })
          return true
        })
        .catch(error => {
          console.error(error)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to unlink Evidence',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
        })
    },
  },
}
