<template>
  <b-form>
    <div class="mb-75">
      <label for="add_issue_rich_1">Issue</label>
      <tip-tap-editor
        id="add_issue_rich_1"
        v-model="issue.name"
        placeholder="Briefly describe the Issue..."
        :allow-image-upload="false"
        min-height="6"
        max-height="6"
      />
    </div>

    <div class="mb-75">
      <label for="add_issue_select_1">Classification</label>
      <b-form-select
        id="add_issue_select_1"
        v-model="issue.classification"
        :options="issueClassifications"
      />
    </div>

    <div class="mb-75">
      <label for="add_issue_select_2">Severity</label>
      <b-form-select
        id="add_issue_select_2"
        v-model="issue.severity"
        :options="issueSeverities"
      />
    </div>

    <div class="mb-75">
      <label for="add_issue_select_3">Status</label>
      <b-form-select
        id="add_issue_select_3"
        v-model="issue.status"
        :options="issueStatuses"
      />
    </div>

    <div class="mb-75">
      <label for="add_issue_rich_2">Additional Details</label>
      <tip-tap-editor
        id="add_issue_rich_2"
        v-model="issue.description"
        placeholder="Give some more context to the Issue if required..."
        :allow-image-upload="false"
        min-height="8"
        max-height="8"
      />
    </div>
  </b-form>
</template>

<script>

import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    TipTapEditor,
  },
  props: {
    issue: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      issueClassifications: 'constants/issueClassifications',
      issueSeverities: 'constants/issueSeverities',
      issueStatuses: 'constants/issueStatuses',
    }),
  },
}
</script>
