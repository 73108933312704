export class BehavioursApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get Bt Requirements
     * @param btId
     * @returns any Successful Response
     * @throws ApiError
     */
    getBtRequirements(btId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/behaviours/requirements/{bt_id}',
            path: {
                'bt_id': btId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Bt Test Cases
     * @param btId
     * @returns any Successful Response
     * @throws ApiError
     */
    getBtTestCases(btId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/behaviours/tests/{bt_id}',
            path: {
                'bt_id': btId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Bt Components
     * @param btId
     * @returns any Successful Response
     * @throws ApiError
     */
    getBtComponents(btId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/behaviours/components/{bt_id}',
            path: {
                'bt_id': btId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
