export class CeleryApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Task Calculate Primes
     * @returns any Successful Response
     * @throws ApiError
     */
    taskCalculatePrimes() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/async_tasker/do_work',
        });
    }
    /**
     * Task List Models
     * @returns any Successful Response
     * @throws ApiError
     */
    taskListModels() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/async_tasker/list_models',
        });
    }
    /**
     * Task Status
     * @param taskId
     * @returns any Successful Response
     * @throws ApiError
     */
    taskStatus(taskId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/async_tasker/tasks/{task_id}',
            path: {
                'task_id': taskId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
