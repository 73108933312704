import store from '@/store'
import Vue from 'vue'

// axios
import axios from 'axios'
import updateToken from '@/auth/middleware/updateToken'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' },
})

if (process.env.NODE_ENV === 'production') {
  axiosIns.defaults.baseURL = `${window.location.protocol}//api.${window.location.host}`
}

axiosIns.interceptors.request.use(async config => {
  const token = await updateToken()
  config.headers.Authorization = `Bearer ${token}`
      if(store.state.model.database || sessionStorage.getItem("X-Target")){
      config.headers['X-Target'] = store.state.model.database ? store.state.model.database : sessionStorage.getItem("X-Target")
      store.state.model.database = sessionStorage.getItem("X-Target") ? sessionStorage.getItem("X-Target") : store.state.model.database
    }
  return config
})

Vue.prototype.$http = axiosIns

export default axiosIns
