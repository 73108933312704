import coreService from '@/libs/api-services/core-service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import Vue from 'vue'

const getDefaultState = () => ({
  list: [],
  selectedSpecObj: null,
  selectedSpecETag: '',
})

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    SET_SPEC_LIST: (state, data) => { state.list = data },
    SET_SPEC_METADATA: (state, data) => {
      const map = new Map()
      state.list.forEach(item => map.set(item.id, item))
      data.forEach(item => map.set(item.id, { ...map.get(item.id), ...item }))
      state.list = Array.from(map.values())
    },
    SET_SELECTED_SPEC_OBJ: (state, data) => { state.selectedSpecObj = data },
    SET_SELECTED_SPEC_ETAG: (state, data) => { state.selectedSpecETag = data },
    ADD_SPECIFICATION: (state, data) => {
      const newSpec = {
        ...data,
        metadata: {
          default: false,
          requirements: 0,
        },
      }
      state.list = [newSpec, ...state.list]
    },
    UPDATE_SPECIFICATION: (state, data) => {
      const index = state.list.findIndex(s => s.id === data.id)
      const updated = { ...state.list[index], ...data }
      Object.assign(state.list[index], updated)
    },
    DELETE_SPECIFICATION: (state, data) => {
      const index = state.list.findIndex(s => s.id === data.id)
      if (index !== -1) {
        state.list.splice(index, 1)
      }
    },
    CLEAR_SELECTED_SPEC_DATA: state => {
      state.selectedSpecObj = null
      state.selectedSpecETag = ''
    },
    CLEAR_ALL: state => { Object.assign(state, getDefaultState()) },
  },
  actions: {
    getAllSpecifications: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'Model-Id': modelId } }
      return coreService
        .get('/v1/specifications', config)
        .then(({ data }) => { commit('SET_SPEC_LIST', data) })
        .catch(error => console.error(error))
    },
    getSpecificationListMetadata: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'Model-Id': modelId } }
      return coreService
        .get('/v1/specifications/metadata', config)
        .then(({ data }) => { commit('SET_SPEC_METADATA', data) })
    },
    getSpecification: ({ commit }, specId) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'Model-Id': modelId } }
      return coreService
        .get(`/v1/specifications/${specId}`, config)
        .then(response => {
          commit('SET_SELECTED_SPEC_OBJ', response.data)
          commit('SET_SELECTED_SPEC_ETAG', response.headers.etag)
        })
        .catch(error => console.error(error))
    },
    createSpecification: ({ commit }, payload) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'Model-Id': modelId } }
      return coreService
        .post('/v1/specifications', payload, config)
        .then(response => {
          if (response.status === 201) {
            commit('ADD_SPECIFICATION', response.data)
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: 'Specification created',
                text: response.data.title,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            return response
          }
          return undefined
        })
        .catch(error => console.error(error))
    },
    updateSpecification: ({ state, commit }, payload) => {
      const { modelId } = router.currentRoute.params
      const config = {
        headers: { 'Model-Id': modelId, 'If-Match': state.selectedSpecETag },
      }
      return coreService
        .patch(`/v1/specifications/${state.selectedSpecObj.id}`, payload, config)
        .then(({ data }) => {
          if (data) {
            commit('UPDATE_SPECIFICATION', data)
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: 'Specification updated',
                text: data.title,
                icon: 'Edit2Icon',
                variant: 'success',
              },
            })
            commit('CLEAR_SELECTED_SPEC_DATA')
            return data
          }
          return undefined
        })
        .catch(error => console.error(error))
    },
    deleteSpecification: ({ state, commit }) => {
      const { modelId } = router.currentRoute.params
      const config = {
        headers: { 'Model-Id': modelId, 'If-Match': state.selectedSpecETag },
      }
      return coreService
        .delete(`/v1/specifications/${state.selectedSpecObj.id}`, config)
        .then(response => {
          if (response.status === 200) {
            commit('DELETE_SPECIFICATION', state.selectedSpecObj)
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: 'Specification deleted',
                text: state.selectedSpecObj.title,
                icon: 'Trash2Icon',
                variant: 'danger',
              },
            })
            commit('CLEAR_SELECTED_SPEC_DATA')
            return true
          }
          return false
        })
        .catch(error => console.error(error))
    },
    copySpecification: ({ state, commit }, payload) => {
      const { modelId } = router.currentRoute.params
      const config = {
        headers: { 'Model-Id': modelId, 'If-Match': state.selectedSpecETag },
      }
      return coreService
        .post(`/v1/specifications/${state.selectedSpecObj.id}/copy`, payload, config)
        .then(({ data }) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Specification Copied',
              icon: 'Edit2Icon',
              variant: 'success',
            },
          })
          commit('CLEAR_SELECTED_SPEC_DATA')
          return data
        })
        .catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to copy Specification',
              icon: 'XIcon',
              variant: 'danger',
              text: error.response.data.detail,
            },
          })
          console.error(error)
        })
    },
    clearSpecification: ({ commit }) => { commit('CLEAR_ALL') },
  },
}
