<template>
  <div>
    <b-list-group-item
      class="entity"
      @click="showDetails"
      @contextmenu.prevent="$refs.menu.open"
    >
      <!-- Top Row -->
      <div class="d-inline-flex w-100 justify-content-between">
        <span class="text-primary font-weight-bold">
          {{ component.qualified_name ? component.qualified_name : component.name }}
          <span
            v-if="showDets && currentModelId === cptDetail.context.details.model && cptDetail.context.parent_rel"
            class="ml-1 text-muted"
          >
            <span v-if="cptDetail.context.parent_rel.rel_type === 'PART_OF'">
              is a part of <span class="text-bold text-info">{{ cptDetail.context.parent_rel.parent_name }}</span>
            </span>
            <span v-else>
              is a type of <span class="text-bold text-info">{{ cptDetail.context.parent_rel.parent_name }}</span>
            </span>
          </span>
        </span>

        <div>
          <span
            v-for="(data, index) in entityType.sort()"
            :key="index"
            class="mr-1 align-middle"
          >
            <feather-icon
              v-b-tooltip.hover.above="`Type: ${data}`"
              :icon="entityLabel(data)"
              class="font-small-2 mr-50 text-info"
            />
            <span class="font-small-2 font-weight-bolder">{{ data }}</span>
          </span>
        </div>
      </div>

      <div v-if="showDets">
        <div v-if="currentModelId === cptDetail.context.details.model">
          <div class="mt-1 scrollable">
            <div class="d-inline-flex w-100 justify-content-around">
              <span>
                Stereotype{{ cptDetail.context.labels | handlePluralSuffix }}:
                <span class="ml-1">{{ cptDetail.context.labels.join(", ") }}</span>
              </span>
              <b-button size="sm" variant="outline-success" @click="routeToEntity()">
                Show in Ontology
              </b-button>
            </div>
            <div
              v-if="cptDetail.context.details.description"
              class="mt-1 ml-50"
            >
              <div v-sanitized-html="cptDetail.context.details.description" />
            </div>

            <hr class="mb-2">

            <div v-if="cptDetail.context.labels.includes('Function') || cptDetail.context.labels.includes('Capability') || cptDetail.context.labels.includes('Activity') || cptDetail.context.labels.includes('Process')">
              <TestReadinessLevel :trl="cptDetail.context.details.trl" class="mb-1" />
              <OperationalStatus :operational-status="cptDetail.context.details.operational_status" />
              <hr>
              <b-row>
                <b-col>
                  <b class="text-primary">
                    Performers
                  </b>
                  <div v-if="selected_performers.length > 0">
                    <small>{{ selected_performers.join(" | ") }}</small>
                  </div>
                  <div v-else>
                    <span class="text-danger"><small>No allocated performers</small></span>
                  </div>
                </b-col>
                <b-col>
                  <b class="text-primary">
                    Enablers
                  </b>
                  <div v-if="selected_enablers.length > 0">
                    <small>{{ selected_enablers.join(" | ") }}</small>
                  </div>
                  <div v-else>
                    <span><small>No allocated enablers</small></span>
                  </div>
                </b-col>
                <b-col>
                  <b class="text-primary">
                    Beneficiaries
                  </b>
                  <div v-if="selected_beneficiaries.length > 0">
                    <small>{{ selected_beneficiaries.join(" | ") }}</small>
                  </div>
                  <div v-else>
                    <span><small>No linked beneficiaries</small></span>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b class="text-primary">
                    Resources Produced/Consumed/Used
                  </b>
                  <div v-if="used_resource.length > 0">
                    <small>{{ used_resource.join(" | ") }}</small>
                  </div>
                  <div v-else>
                    <span><small>No linked resources</small></span>
                  </div>
                </b-col>
              </b-row>
              <hr class="mb-2">
              <b class="text-primary">
                Interfaces
              </b>
              <b-row>
                <b-col>
                  <b>Outputs</b>
                  <div v-if="outputs.length > 0">
                    <small>{{ outputs.join("<br>") }}</small>
                  </div>
                  <div v-else>
                    <span><small>No outputs</small></span>
                  </div>
                </b-col>
                <b-col>
                  <b>Inputs</b>
                  <div v-if="inputs.length > 0">
                    <small>{{ inputs.join("<br>") }}</small>
                  </div>
                  <div v-else>
                    <span><small>No inputs</small></span>
                  </div>
                </b-col>
              </b-row>
              <hr class="mb-1">
              <b-row>
                <b-col>
                  <b class="text-primary">
                    Requirements
                  </b>
                  <div v-if="selected_requirements.length > 0">
                    <small>{{ selected_requirements.join(", ") }}</small>
                  </div>
                  <div v-else>
                    <span><small>No allocated requirements</small></span>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div v-else-if="cptDetail.context.labels.includes('Performer') || cptDetail.context.labels.includes('System') || cptDetail.context.labels.includes('Service') || cptDetail.context.labels.includes('Node') || cptDetail.context.labels.includes('HumanActor')">
              <div v-if="!cptDetail.context.labels.includes('HumanActor')">
                <TestReadinessLevel :trl="cptDetail.context.details.trl" class="mb-1" />
                <OperationalStatus :operational-status="cptDetail.context.details.operational_status" />
              </div>
              <hr v-if="!cptDetail.context.labels.includes('HumanActor')">
              <b-row>
                <b-col>
                  <b class="text-primary">
                    Functions/Activities Performed
                  </b>
                  <div v-if="selected_performances.length > 0">
                    <small>{{ selected_performances.join(" | ") }}</small>
                  </div>
                  <div v-else>
                    <span><small>No functions/activities performed</small></span>
                  </div>
                </b-col>
                <b-col>
                  <b class="text-primary">
                    Enables
                  </b>
                  <div v-if="enables.length > 0">
                    <small>{{ enables.join(" | ") }}</small>
                  </div>
                  <div v-else>
                    <span><small>No functions/activities enabled</small></span>
                  </div>
                </b-col>
                <b-col>
                  <b class="text-primary">
                    Benefits From
                  </b>
                  <div v-if="benefits_from.length > 0">
                    <small>{{ benefits_from.join(" | ") }}</small>
                  </div>
                  <div v-else>
                    <span><small>Benefits from no functions/activities</small></span>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-50">
                <b-col>
                  <b class="text-primary">
                    Other Associations
                  </b>
                  <div v-if="other_associations.length > 0">
                    <small>{{ other_associations.join(" | ") }}</small>
                  </div>
                  <div v-else>
                    <span><small>No other associations</small></span>
                  </div>
                </b-col>
              </b-row>
              <hr class="mb-2">
              <b class="text-primary">
                Interfaces
              </b>
              <b-row>
                <b-col>
                  <b>Outputs</b>
                  <div v-if="outputs.length > 0">
                    <small>{{ outputs.join(" | ") }}</small>
                  </div>
                  <div v-else>
                    <span><small>No outputs</small></span>
                  </div>
                </b-col>
                <b-col>
                  <b>Inputs</b>
                  <div v-if="inputs.length > 0">
                    <small>{{ inputs.join(" | ") }}</small>
                  </div>
                  <div v-else>
                    <span><small>No inputs</small></span>
                  </div>
                </b-col>
              </b-row>
              <hr class="mb-1">
              <b-row>
                <b-col>
                  <b class="text-primary">
                    Requirements
                  </b>
                  <div v-if="selected_requirements.length > 0">
                    <small>{{ selected_requirements.join(", ") }}</small>
                  </div>
                  <div v-else>
                    <span><small>No allocated requirements</small></span>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div v-else>
              <TestReadinessLevel :trl="cptDetail.context.details.trl" class="mb-1" />
              <OperationalStatus :operational-status="cptDetail.context.details.operational_status" />
              <hr>
              <b-row>
                <b-col>
                  <b class="text-primary">
                    Produced/Consumed/Used in Functions
                  </b>
                  <div v-if="used_by.length > 0">
                    <small>{{ used_by.join(" | ") }}</small>
                  </div>
                  <div v-else>
                    <span><small>Not used by any functions/activities</small></span>
                  </div>
                </b-col>
                <b-col>
                  <b class="text-primary">
                    Exchanged on Interfaces
                  </b>
                  <div v-if="use_on.length > 0">
                    <small>{{ use_on.join(" | ") }}</small>
                  </div>
                  <div v-else>
                    <span><small>Not exchanged on an interface</small></span>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-50">
                <b-col>
                  <b class="text-primary">
                    Other Associations
                  </b>
                  <div v-if="other_associations.length > 0">
                    <small>{{ other_associations.join(" | ") }}</small>
                  </div>
                  <div v-else>
                    <span><small>No other associations</small></span>
                  </div>
                </b-col>
              </b-row>
              <hr class="mb-1">
              <b-row>
                <b-col>
                  <b class="text-primary">
                    Requirements
                  </b>
                  <div v-if="selected_requirements.length > 0">
                    <small>{{ selected_requirements.join(", ") }}</small>
                  </div>
                  <div v-else>
                    <span><small>No allocated requirements</small></span>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <div v-else>
          This component is from linked model: {{ getLinkedModelName() }}
        </div>
      </div>
    </b-list-group-item>

    <!-- context -->
    <vue-context ref="menu">
      <li>
        <b-link
          v-for="data in menuData"
          :key="data.text"
          class="d-flex align-items-center"
          @click="data.method()"
        >
          <feather-icon
            :icon="data.icon"
            class="text-primary"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
      </li>
    </vue-context>
  </div>
</template>

<script>
import OperationalStatus from '@/components/Components/OperationalStatus.vue'
import TestReadinessLevel from '@/components/Components/TestReadinessLevel.vue'
import VueContext from 'vue-context'
import { BLink } from 'bootstrap-vue'

export default {
  name: 'ListGroupItemEntity',
  components: {
    OperationalStatus,
    TestReadinessLevel,
    VueContext,
    BLink,
  },
  props: {
    component: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cptDetail: null,
      menuData: [
        { icon: 'ChevronRightIcon', text: 'Open in Ontology', method: () => this.routeToEntity() },
        { icon: 'ChevronsRightIcon', text: 'Open parent in Ontology', method: () => this.routeToParentEntity() },
      ],
      showDets: false,
    }
  },
  computed: {
    currentModelId() {
      return this.$store.state.model.id
    },
    entityType() {
      return this.component.labels && Array.isArray(this.component.labels) ? this.component.labels.filter(e => e !== 'Component') : []
    },
    selected_performers() {
      return this.cptDetail ? this.cptDetail.context.relations.edges.filter(e => e.name === 'Performs').map(x => x.source_str) : []
    },
    selected_performances() {
      return this.cptDetail ? this.cptDetail.context.relations.edges.filter(e => e.name === 'Performs').map(x => x.target_str) : []
    },
    selected_enablers() {
      return this.cptDetail ? this.cptDetail.context.relations.edges.filter(e => e.name === 'Enabled by').map(x => x.target_str) : []
    },
    enables() {
      return this.cptDetail ? this.cptDetail.context.relations.edges.filter(e => e.name === 'Enabled by').map(x => x.source_str) : []
    },
    selected_beneficiaries() {
      return this.cptDetail ? this.cptDetail.context.relations.edges.filter(e => e.name === 'Benefits').map(x => x.target_str) : []
    },
    benefits_from() {
      return this.cptDetail ? this.cptDetail.context.relations.edges.filter(e => e.name === 'Benefits').map(x => x.source_str) : []
    },
    used_resource() {
      return this.cptDetail ? this.cptDetail.context.relations.edges.filter(e => e.name === 'Uses').map(x => x.target_str) : []
    },
    used_by() {
      return this.cptDetail ? this.cptDetail.context.relations.edges.filter(e => e.name === 'Uses').map(x => x.source_str) : []
    },
    other_associations() {
      return this.cptDetail ? this.cptDetail.context.relations.edges.filter(e => !(['Uses', 'Benefits', 'Enabled by', 'Performs'].includes(e.name))).map(x => `${x.source_str}  ${x.name} ${x.target_str}`) : []
    },
    outputs() {
      return this.cptDetail ? this.cptDetail.context.relationships.filter(e => e.labels.includes('Interface') && e.rel_type === 'SOURCE').map(x => x.target_props.name) : []
    },
    inputs() {
      return this.cptDetail ? this.cptDetail.context.relationships.filter(e => e.labels.includes('Interface') && e.rel_type === 'DESTINATION').map(x => x.target_props.name) : []
    },
    use_on() {
      return this.cptDetail ? this.cptDetail.context.relationships.filter(e => e.labels.includes('Interface') && e.rel_type === 'RESOURCE').map(x => x.target_props.name) : []
    },
    selected_requirements() {
      return this.cptDetail ? this.cptDetail.context.relationships.filter(e => e.labels.includes('Requirement')).map(x => x.target_props.display_id) : []
    },
  },
  watch: {
    component(newval) {
      this.showDets = false
      this.cptDetail = null
    },
  },
  methods: {
    getLinkedModelName() {
      return this.$store.state.model.linkedModels.find(m => m.id === this.cptDetail.context.details.model)?.name || 'Unknown'
    },
    showDetails() {
      if (this.showDets) {
        this.showDets = false
        this.cptDetail = null
      } else {
        const params = { model: this.$store.state.model.id }
        this.$http
          .get(`/api/v2/domain_model/entity/${this.component.id}`, { params })
          .then(response => {
            this.cptDetail = response.data
            this.showDets = true
          })
          .catch(r => console.error(r))
      }
    },
    routeToEntity() {
      this.$bvModal
        .msgBoxConfirm('Are you sure you wish to leave this page? Any unsaved changes will be lost.', {
          title: 'Open Entity',
          size: 'sm',
          okVariant: 'success',
          okTitle: 'Open Entity',
          cancelTitle: 'Dismiss',
          cancelVariant: 'outline-danger',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$router.push(
              {
                name: 'domain_ontology_focus',
                params: { focus: this.component.id },
              },
            )
          }
        })
    },
    routeToParentEntity() {
      this.$router.push(
        {
          name: 'domain_ontology_focus',
          params: { focus: this.component.parent },
        },
      )
    },
    entityLabel(data) {
      // Depending on the label of the Entity, return a different icon
      switch (data) {
      case 'Resource':
        return 'BookOpenIcon'
      case 'Function':
        return 'ZapIcon'
      case 'Benefit':
        return 'ThumbsUpIcon'
      case 'View':
        return 'EyeIcon'
      case 'Performer':
        return 'UserIcon'
      case 'HumanActor':
        return 'UserIcon'
      case 'Standard':
        return 'FileTextIcon'
      case 'Reference':
        return 'EyeIcon'
      case 'Agreement':
        return 'PenToolIcon'
      case 'Capability':
        return 'CheckSquareIcon'
      case 'Activity':
        return 'ActivityIcon'
      case 'Service':
        return 'ToolIcon'
      case 'System':
        return 'ServerIcon'
      case 'Objective':
        return 'TargetIcon'
      case 'Node':
        return 'GitCommitIcon'
      default:
        return ''
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-context.scss';

.scrollable {
  overflow: auto;
  height: 500px;
}

.btn-flat-primary:focus {
  background-color: unset;
}

.entity {
  background-color: rgba(22, 29, 49, 0.02) !important;
  border: 1px dashed;
  border-left: 0;
  border-right: 0;
}

.entity:hover {
  cursor: pointer;
  background-color: rgba(22, 29, 49, 0.05) !important;
}

.dark-layout {
  .entity {
    background-color: rgba(22, 29, 49, 0.3) !important;
  }

  .entity:hover {
    background-color: rgba(22, 29, 49, 1) !important;
  }
}
</style>
