export default function getEnv(name) {
  /**
   * Get the dynamic environment variables from the window.configs object
   *  Dynamic variables will have priority over the baked-in process.env environment variables
   */
  const value = window?.configs?.[name] || process.env[name]
  if (typeof value === 'undefined') {
    console.log(`Environment variable ${name} is missing`)
    return ''
  }
  return value
}
