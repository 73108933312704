<template>
<div>
  <p class="my-0 font-weight-bold text-primary">
    Operational Status
  </p>

  <div v-if="operationalStatus || operationalStatus === 0">
    <b-badge :class="resolveClass(operationalStatus)">
      {{ resolveOperationalStatus(operationalStatus).name }}
    </b-badge>
    <span class="font-small-2 font-weight-bold">
      : {{ resolveOperationalStatus(operationalStatus).description }}
    </span>
  </div>
  <div v-else>
    <b-badge class="unevaluated">
      Unevaluated
    </b-badge>
    <span class="font-small-2 font-weight-bold">
      : no data available
    </span>
  </div>
</div>
</template>

<script>
import store from '@/store'

export default {
  name: 'OperationalStatus',
  props: {
    operationalStatus: {
      type: Number,
      default: -1, // Unevaluated is -1
    },
  },
  setup(props, context) {
    const resolveOperationalStatus = value => store.state.constants.operationalStatus.find(o => o.id === value)
    const resolveClass = value => {
      if (value === -1) {
        return 'unevaluated'
      }
      if (value === 0) {
        return 'fullyOperational'
      }
      if (value === 1) {
        return 'degradedMinor'
      }
      if (value === 2) {
        return 'degradedSignificant'
      }
      if (value === 3) {
        return 'nonOperational'
      }
      return 'unevaluated'
    }

    return {
      resolveOperationalStatus,
      resolveClass,
    }
  },
}
</script>

<style scoped lang="scss">
.unevaluated {
  background-color: #546E7A;
}

.fullyOperational {
  background-color: #00C853;
}

.degradedMinor {
  background-color: #FFB300;
}

.degradedSignificant {
  background-color: #F57C00;
}

.nonOperational {
  background-color: #FF3D00;
}
</style>
