export class UtilsApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Details
     * @returns any Successful Response
     * @throws ApiError
     */
    details() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/utils/details',
        });
    }
    /**
     * Ping
     * @returns any Successful Response
     * @throws ApiError
     */
    ping() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/utils/ping',
        });
    }
}
