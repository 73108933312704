export class ClassDiagramsApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get All
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getAll(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/class_diagrams/all/{model_id}',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get
     * @param diagramId
     * @returns any Successful Response
     * @throws ApiError
     */
    get(diagramId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/class_diagrams/{diagram_id}',
            path: {
                'diagram_id': diagramId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update
     * @param diagramId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    update(diagramId, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/class_diagrams/{diagram_id}',
            path: {
                'diagram_id': diagramId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete
     * @param diagramId
     * @returns any Successful Response
     * @throws ApiError
     */
    delete(diagramId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/class_diagrams/{diagram_id}',
            path: {
                'diagram_id': diagramId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    create(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/class_diagrams/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Class Diagram From Root
     * @param root
     * @returns any Successful Response
     * @throws ApiError
     */
    getClassDiagramFromRoot(root) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/class_diagrams/from_root/{root}',
            path: {
                'root': root,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Class Diagram Metadata
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateClassDiagramMetadata(requestBody) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/class_diagrams/class_diagram_metadata',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Expand
     * @param componentId
     * @param directedOnly
     * @returns any Successful Response
     * @throws ApiError
     */
    expand(componentId, directedOnly) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/class_diagrams/expand/{component_id}',
            path: {
                'component_id': componentId,
            },
            query: {
                'directed_only': directedOnly,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Find
     * @param term
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    find(term, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/class_diagrams/find_class_diagram/{term}',
            path: {
                'term': term,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component For Class Diagram
     * @param componentId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentForClassDiagram(componentId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/class_diagrams/get_component_class_diagram/{component_id}',
            path: {
                'component_id': componentId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove Node
     * @param componentId
     * @param diagramId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    removeNode(componentId, diagramId, modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/class_diagrams/{diagram_id}/{component_id}',
            path: {
                'component_id': componentId,
                'diagram_id': diagramId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
