import Vue from 'vue'
import store from '@/store'
import { nextTick } from '@vue/composition-api'

/**
 * Helper functions to implement lazy loading of DOM elements for the Requirements table view
 * @returns {{resetRowHeights: resetRowHeights}}
 */
export default function utilsLazyLoad() {
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.02, // Show element if more than 2% of it is on the screen
  }

  const handleIntersection = (entries, observer) => {
    if (store.state.requirementsTable.allRequirements.length > 0) {
      entries.forEach(entry => {
        const el = entry.target
        const index = el.getAttribute('data-index')
        if (entry.isIntersecting) {
          // Show the element if it is in view
          Vue.set(store.state.requirementsTable.allRequirements[index], 'showItem', true)
          nextTick(() => {
            // Once it's loaded, save the height as it's minHeight to avoid jerky behaviour when
            //  scrolling up
            el.style.minHeight = `${el.offsetHeight}px`
          })
        } else {
          // Hide the element if it is out of view
          Vue.set(store.state.requirementsTable.allRequirements[index], 'showItem', false)
        }
      })
    }
  }

  /**
   * Lazy load and unload elements from the DOM
   */
  const observer = new IntersectionObserver(handleIntersection, options)
  const registerLazyLoadObserver = () => {
    // Start observing all the .lazy rows
    const rows = document.querySelectorAll('.lazy')
    rows.forEach(row => {
      observer.observe(row)
    })
  }

  const unregisterLazyLoadObserver = () => {
    const rows = document.querySelectorAll('.lazy')
    rows.forEach(row => {
      observer.unobserve(row)
    })
  }

  const setAllRowHeights = async () => {
    // Show all items for height calculation
    store.state.requirementsTable.allRequirements.forEach((_, index) => {
      Vue.set(store.state.requirementsTable.allRequirements[index], 'showItem', true)
    })

    // Wait for the DOM to finish rendering the items
    await Vue.nextTick()

    const elements = document.querySelectorAll('.lazy')
    const heights = []

    // First read: Calculate and store heights to avoid layout thrashing
    elements.forEach(el => {
      heights.push(el.offsetHeight)
    })

    // Then write: Apply the minHeight style
    elements.forEach((el, index) => {
      el.style.minHeight = `${heights[index]}px`
    })

    // Hide all items again after minHeight is set
    store.state.requirementsTable.allRequirements.forEach((_, index) => {
      Vue.set(store.state.requirementsTable.allRequirements[index], 'showItem', false)
    })
  }

  /**
   * Reset all the Requirement row heights back to their default height
   * (Useful when changing between Specifications)
   */
  const resetRowHeights = () => {
    document.querySelectorAll('.lazy').forEach(el => {
      el.style.minHeight = 0
    })
  }

  return {
    registerLazyLoadObserver,
    unregisterLazyLoadObserver,
    setAllRowHeights,
    resetRowHeights,
  }
}
