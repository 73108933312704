import Vue from 'vue'
import VueRouter from 'vue-router'

// Route imports
import AuthRoutes from '@/auth/router'
import CreateRoutes from './modules/create'
import ModelRoutes from './modules/models'
import SettingsRoutes from './modules/settings'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'model_browse' },
  },
  {
    path: '/browse',
    name: 'model_browse',
    component: () => import('@/views/Models/ModelsBrowser.vue'),
    meta: {
      pageTitle: 'Browse Models',
      auth: true,
      breadcrumb: [
        {
          text: 'Browse Models',
          active: true,
        },
      ],
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  ...AuthRoutes,
  ...CreateRoutes,
  ...ModelRoutes,
  ...SettingsRoutes,
]

// scrollBehavior:
// - only available in html5 history mode
// - defaults to no scroll behavior
// - return false to prevent scroll
const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    // savedPosition is only available for popstate navigations.
    //  eg: Forward/Back with browser buttons
    return savedPosition
  }

  const position = {}

  // scroll to anchor by returning the selector
  if (to.hash) {
    position.selector = to.hash

    // specify offset of the element
    position.offset = { y: 200 }
    if (to.offset) {
      position.offset = to.offset
    }

    // Example code to handle elements with an ID that starts with a number.
    //  In this case, the element has '#1number' as its ID.
    // bypass #1number check
    if (/^#\d/.test(to.hash) || document.querySelector(to.hash)) {
      return position
    }

    // if the returned position is falsy or an empty object,
    // will retain current scroll position.
    return false
  }

  return new Promise((resolve, reject) => {
    // check if any matched route config has meta that requires scrolling to top
    if (to.matched.some(m => m.meta.scrollToTop)) {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      position.x = 0
      position.y = 0
    }

    //   // wait for the out transition to complete (if necessary)
    //   console.log(app)
    //   app.$root.once('triggerScroll', () => {
    //     // if the resolved position is falsy or an empty object,
    //     // will retain current scroll position.
    //     resolve(position)
    //   })
    // })
    setTimeout(() => {
      resolve(position)
    }, 500)
  })
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior,
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.remove()
  }
})

export default router
