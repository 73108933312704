export class NoteApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Create Note
     * @param modelId
     * @param requestBody
     * @returns Note Successful Response
     * @throws ApiError
     */
    createNote(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/note',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Note
     * @param noteId
     * @param modelId
     * @returns Note Successful Response
     * @throws ApiError
     */
    getNote(noteId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/note/{note_id}',
            path: {
                'note_id': noteId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Note
     * @param noteId
     * @param modelId
     * @param ifMatch
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteNote(noteId, modelId, ifMatch) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/note/{note_id}',
            path: {
                'note_id': noteId,
            },
            headers: {
                'model-id': modelId,
                'if-match': ifMatch,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Note
     * @param noteId
     * @param modelId
     * @param ifMatch
     * @param requestBody
     * @returns Note Successful Response
     * @throws ApiError
     */
    updateNote(noteId, modelId, ifMatch, requestBody) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/note/{note_id}',
            path: {
                'note_id': noteId,
            },
            headers: {
                'model-id': modelId,
                'if-match': ifMatch,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * List Notes For Node
     * @param nodeId
     * @param modelId
     * @returns Note Successful Response
     * @throws ApiError
     */
    listNotesForNode(nodeId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/note/list/{node_id}',
            path: {
                'node_id': nodeId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
