import beforeEnter from '@/router/modules/models/beforeEnter'
import BehaviourRoutes from '@/router/modules/models/behaviour'
import CanvasRoutes from '@/router/modules/models/canvas'
import DomainModelRoutes from '@/router/modules/models/domainModel'
import HelpDesk from '@/router/modules/models/helpDesk'
import IdeasRoutes from '@/router/modules/models/ideas'
import IssuesRoutes from '@/router/modules/models/issues'
import MetricsRoutes from '@/router/modules/models/metrics'
import ModelSnapshots from '@/router/modules/models/model_snapshots'
import NodeHistory from '@/router/modules/models/node_history'
import ReleaseRoutes from '@/router/modules/models/releases'
import ConfigurationRoutes from '@/router/modules/models/configurations'
import RequirementsTableLegacyRoutes from '@/router/modules/models/requirements_table_legacy'
import RequirementsTableRoutes from '@/router/modules/models/requirements_table'
import SpecificationRoutes from '@/router/modules/models/specification'
import SpecificationsRoutes from '@/router/modules/models/specifications'
import TestsRoutes from '@/router/modules/models/tests'
import TradeRoutes from '@/router/modules/models/trades'
import CollectionRoutes from '@/router/modules/models/collections'

const routes = [
  {
    path: '/error-no-model',
    name: 'error-no-model',
    component: () => import('@/views/error/ErrorNoModel.vue'),
    meta: {
      pageTitle: 'No Workspace',
      layout: 'full',
    },
  },
  {
    path: '/model/:modelId',
    name: 'model_dashboard',
    component: () => import('@/views/Models/ModelDashboard.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Dashboard',
      auth: true,
    },
  },
  {
    path: '/model/:modelId/links',
    name: 'model_links',
    component: () => import('@/views/Models/ModelLinks.vue'),
    props: true,
    meta: {
      pageTitle: 'Inter-model Links',
      auth: true,
      breadcrumb: [
        {
          text: 'Inter-model Links',
          active: true,
        },
      ],
    },
  },
  ...BehaviourRoutes,
  ...CanvasRoutes,
  ...DomainModelRoutes,
  ...IdeasRoutes,
  ...IssuesRoutes,
  ...MetricsRoutes,
  ...ModelSnapshots,
  ...NodeHistory,
  ...ReleaseRoutes,
  ...ConfigurationRoutes,
  ...RequirementsTableRoutes,
  ...RequirementsTableLegacyRoutes,
  ...SpecificationRoutes,
  ...SpecificationsRoutes,
  ...TestsRoutes,
  ...TradeRoutes,
  ...HelpDesk,
  ...CollectionRoutes,
]

export default routes
