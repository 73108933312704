import getEnv from '@/utils/env'
import { getBlobUrlFromApiData } from '@core/utils/utils'
import * as Sentry from '@sentry/vue'
import Vue from 'vue'
import coreService from '@/libs/api-services/core-service'

const getAvatarUrl = async (id, avatars) => {
  const avatar = avatars.find(a => a.id === id)
  if (avatar && avatar.url) {
    return avatar.url
  }
  const newBlobUrl = await loadAvatarUrl(id)
  avatars.push({
    id,
    url: newBlobUrl,
  })
  return newBlobUrl
}

const loadAvatarUrl = async id => {
  try {
    const config = { responseType: 'blob' }
    const imageResponse = await coreService.get(`/auth/user/${id}/avatar`, config)
    return getBlobUrlFromApiData(imageResponse.data)
  } catch (e) {
    console.log('No avatar for user id', id, e)
  }
  return ''
}

export default {
  namespaced: true,
  state: {
    id: '',
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    roles: null,
    attributes: [],
    active_sessions: [],
    emailVerified: false,
    token: '',
    workingOn: 'All',
    avatarBlobUrl: '',
    allUsers: [],
    allGroups: [],
    allRoles: [],
    allAvatars: [],
  },
  getters: {
    allUsers: state => state.allUsers,
    allGroups: state => state.allGroups,
    allRoles: state => state.allRoles,
    fullName: state => `${state.firstName} ${state.lastName}`,
    userRole: state => {
      const email = { state }
      // TODO Read the JWT Token directly. The code below is just a placeholder.
      // $keycloak.getToken()
      // if token.email === email {
      //   do stuff
      // }
      if (email === 'contributor@mail.com') {
        /**
         * CONTRIBUTOR: Limited Access User
         * Can’t create workspaces, can be assigned to a workspace to contribute.
         *
         * A Contributor can create, modify and delete:
         *  Specifications
         *  Ideas
         *  Requirements
         *  Test Cases
         *
         * This User is suitable for Junior BAs,
         * general users within an enterprise including technical and business stakeholders.
         */
        return 'CONTRIBUTOR'
      }
      if (email === 'practitioner@mail.com') {
        /**
         * PRACTITIONER: Full Access User
         * Can create, modify and delete all features in Kompozition.
         *
         * This User is suitable for Systems Engineers, Architects, Senior BAs
         */
        return 'PRACTITIONER'
      }
      /**
       * CONSUMER: Read-Only User
       * Can review workspaces they have been allocated to and export to relevant file formats.
       * Has no ability to create, modify or delete and features within Kompozition.
       *
       * This User is suitable for Project Managers, Senior Executives and any other stakeholder
       * that needs to review and consume the data in a workspace.
       */
      return 'CONSUMER'
    },
  },
  mutations: {
    storeUserData(state, data) {
      state.id = Vue.$keycloak.subject
      state.username = data.username
      state.email = data.email
      state.firstName = data.firstName
      state.lastName = data.lastName
      state.roles = Vue.$keycloak.realmAccess.roles
      state.attributes = data.attributes
      state.token = Vue.$keycloak.token
      state.emailVerified = data.emailVerified
    },
    storeAvatarBlobUrl(state, data) {
      state.avatarBlobUrl = data.avatarBlobUrl
    },
    storeUserWorkingOn(state, data) {
      state.workingOn = data.workingOn
    },
    clearUserData(state) {
      state.id = ''
      state.username = ''
      state.email = ''
      state.firstName = ''
      state.lastName = ''
      state.roles = []
      state.groups = []
      state.attributes = []
      state.active_sessions = []
      state.emailVerified = false
      state.token = ''
      state.workingOn = 'All'
    },
    storeAllUsers(state, data) {
      state.allUsers = data
    },
    clearAllUsers(state) {
      state.allUsers = []
    },
    storeAllGroups(state, data) {
      state.allGroups = data
    },
    clearAllGroups(state) {
      state.allGroups = []
    },
    storeAllRoles(state, data) {
      state.allRoles = data.map(r => r.keycloak_role)
    },
    clearAllRoles(state) {
      state.allRoles = []
    },
    storeUser(state, data) {
      const i = state.allUsers.findIndex(u => u.id === data.id)
      if (i >= 0) {
        state.allUsers.splice(i, 1)
      }
      state.allUsers.push(data)
    },
    storeGroup(state, data) {
      const i = state.allGroups.findIndex(g => g.id === data.id)
      if (i >= 0) {
        state.allUsers.splice(i, 1)
      }
      state.allGroups.push(data)
    },
  },
  actions: {
    async updateUser({
      commit,
      state,
    }, user) {
      user.avatar = await getAvatarUrl(user.id, state.allAvatars)
      commit('storeUser', user)
    },
    async getAllUsers({
      commit,
      state,
    }) {
      const usersResponse = await coreService.usersManagementApi.getAllUsers()
      const users = usersResponse
        .map(u => ({
          ...u,
          jobTitle: u.attributes && u.attributes.jobTitle ? u.attributes.jobTitle[0] : '',
        }))
      commit('storeAllUsers', users)
      users.forEach(u => getAvatarUrl(u.id, state.allAvatars)
        .then(uri => {
          u.avatar = uri
          commit('storeUser', u)
        }))
    },
    async getAllGroups({ commit }) {
      const response = await coreService.groupManagementApi.getAllGroups()
      commit('storeAllGroups', response)
    },
    async getAllRoles({ commit }) {
      const response = await coreService.roleManagementApi.getAllRoles()
      commit('storeAllRoles', response)
    },
    async getUserData({
      commit,
      dispatch,
    }) {
      const userData = await Vue.$keycloak.loadUserProfile()
      if (userData?.id === undefined) {
        commit('clearUserData')
        console.error('Failed to load user profile', userData.text)
        return
      }
      commit('storeUserData', userData)
      if (getEnv('VUE_APP_SENTRY_ENABLED')
        .toLowerCase() === 'true') {
        Sentry.setUser({
          id: userData.id,
          username: userData.email,
          email: userData.email,
        })
      }
      try {
        // I've tried everything to get the generated endpoint client to add a responseType
        const config = { responseType: 'blob' }
        const imageResponse = await this.$coreService.get(`/auth/user/${userData.id}/avatar`, config)
        const blobUrl = getBlobUrlFromApiData(imageResponse.data)
        await dispatch('updateAvatarBlobUrl', blobUrl)
      } catch {
        console.debug('No avatar for user')
      }
    },
    updateWorkingOn: ({
      dispatch,
      commit,
    }, working) => {
      const params = {
        workingOn: working,
      }
      commit('storeUserWorkingOn', params)
    },
    updateAvatarBlobUrl: ({
      dispatch,
      commit,
    }, blobUrl) => {
      const params = {
        avatarBlobUrl: blobUrl,
      }
      commit('storeAvatarBlobUrl', params)
    },
    logout({ commit }) {
      commit('clearUserData')
      Sentry.setUser(null)
      // TODO: Make URL dynamic
      // TODO: Toast Alert('Logged out')
      Vue.$keycloak.logout('localhost:8080')
    },
  },
}
