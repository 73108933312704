import axiosIns from '@/libs/axios'
import router from '@/router'

const getDefaultState = () => ({
  jira_projects: [],
  jira_issues: [],
  jira_stories: [],
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    SET_JIRA_PROJECTS: (state, projects) => { state.jira_projects = projects },
    SET_JIRA_ISSUES: (state, issues) => { state.jira_issues = issues },
    SET_JIRA_STORIES: (state, stories) => { state.jira_stories = stories },
    CLEAR_ALL: state => { Object.assign(state, getDefaultState()) },
  },
  actions: {
    clearJira: ({ commit }) => { commit('CLEAR_ALL') },
    syncProjects: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return axiosIns
        .get('/api/v2/jira/projects', { params })
        .then(({ data }) => commit('SET_JIRA_PROJECTS', data))
        .catch(e => console.error(e))
    },
    syncIssues: ({ commit }, project) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return axiosIns
        .get(`/api/v2/jira/all_issues/${project}`, { params })
        .then(({ data }) => commit('SET_JIRA_ISSUES', data))
        .catch(e => console.error(e))
    },
    syncStories: ({ commit }, project) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return axiosIns
        .get(`/api/v2/jira/all_stories/${project}`, { params })
        .then(({ data }) => commit('SET_JIRA_STORIES', data))
        .catch(e => console.error(e))
    },
    importFromJiraProject: ({ commit }, payload) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      payload.model = params.model
      console.debug('Import from jira payload ', payload)
      return axiosIns
        .post('/api/v2/jira/import', payload, { params })
        .then(({ data }) => {
          commit('SET_JIRA_STORIES', data.stories)
          commit('SET_JIRA_ISSUES', data.issues)
        })
        .catch(e => console.error(e))
    },
  },
}
