<template>
<!--  PLEASE NOTE: title attributes in this component are references by playwright tests -->
  <b-modal
    :id="id"
    :title="titleOverride || 'Link ' + typeName"
    size="xl"
    ok-title="Link Issues"
    ok-variant="success"
    cancel-variant="outline-secondary"
    :hide-footer="instantSave || !showSave"
    lazy
    no-close-on-backdrop
    no-close-on-esc
    @ok="onSubmit"
    @show="onShow"
    @hidden="onHidden"
  >
    <div v-if="isModalShown" id="genericAssociator">
      <div class="d-flex m-25">
        <!-- Available Items -->
        <div class="w-50 d-flex flex-column">
          <div v-if="prefilterEnabled">
            <h6 class="d-inline">
              Filter By {{ prefilterName }}
            </h6>
            <span v-if="canShowAll" class="float-right font-small-2 text-info">
              Clear selection to show all {{ typeName }}
            </span>
            <b-form-group>
              <v-select
                v-model="selectedPreFilterObject"
                :disabled="isLoading"
                :loading="isLoading"
                :label="prefilterLabel"
                :options="selectedPreFilterList"
                :clearable="canShowAll"
                title="Prefilter the items to select from"
              >
                <template #spinner="{ isLoading }">
                  <div
                    v-if="isLoading"
                    style="border-left-color: rgba(88, 151, 251, 0.71)"
                    class="vs__spinner"
                  />
                </template>
              </v-select>
            </b-form-group>
          </div>
          <div class="flex-grow-1 d-flex flex-column">
            <div class="w-100 d-inline-flex justify-content-between">
              <h6>
                <b-badge v-if="availableList" variant="info" class="mr-1">
                  {{ filteredAvailableList.length }} of {{ availableList.length }}
                </b-badge>
                Available {{ typeName }}
              </h6>
              <b-button-toolbar class="d-inline-flex justify-content-end mb-1" style="gap: 0.5rem;">
              <b-button-group>
                <b-button
                  v-if="labelFilterEnabled"
                  size="sm"
                  variant="outline-secondary"
                  title="Clear label filter"
                  @click="selectedLabels = []; searchText = '';"
                >
                  <feather-icon icon="FilterIcon" class="mr-25" />
                  Clear Filter
                </b-button>
                <b-button
                  size="sm"
                  variant="outline-secondary"
                  title="Clear selected items"
                  @click="selectedList = []; searchText = '';"
                >
                  <feather-icon icon="ListIcon" class="mr-25" />
                  Clear Selected ({{ selectedList ? selectedList.length : '0' }})
                </b-button>
              </b-button-group>
              <b-button
                  v-if="fetchFn"
                  size="sm"
                  class="pr-1 pl-1"
                  variant="outline-secondary"
                  title="Refresh"
                  @click="fetchAvailableObjects"
                >
                  <feather-icon icon="RefreshCcwIcon" class="pr-25 text-info" />
              </b-button>
            </b-button-toolbar>
            </div>
            <v-select
                v-if="labelFilterEnabled"
                v-model="selectedLabels"
                :disabled="isLoading"
                :loading="isLoading"
                :options="allDistinctLabels"
                :multiple="true"
                :close-on-select="false"
                @option:selecting="labelChanged"
                title="Filter by label"
              >
              <template #spinner="{ isLoading }">
                <div
                  v-if="isLoading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
              </template>
            </v-select>
            <b-form-input
              v-model="searchText"
              placeholder="Filter..."
              title="Filter available items"
              style="border-bottom-left-radius: 0; border-bottom-right-radius: 0;"
            />
            <b-form-select
              v-model="selectedList"
              :disabled="isLoading"
              class="h-100"
              style="min-height: 35rem; border-top-left-radius: 0; border-top-right-radius: 0; border-top: 0"
              multiple
              title="Select available items"
            >
              <div v-if="availableList">
                {{ availableList[0] }}
              </div>
              <template>
                <b-select-option v-for="opt in filteredPagedAvailableList" :key="opt.id" :value="opt.id">
                  <div class="w-100 text-ellipsis" :title="opt.name || opt.text">
                    <slot name="left" v-bind="opt" />
                  </div>
                </b-select-option>
              </template>
            </b-form-select>
            <b-pagination class="mt-1 w-100"
              v-model="currentPageAvailable"
              :total-rows="rows"
              :per-page="100"
              title="Change pages for available items"
            ></b-pagination>
          </div>
        </div>

        <!-- Assign button -->
        <div class="d-flex flex-column align-self-center mx-50">
          <b-button
            variant="flat-success"
            :disabled="!selectedList.length"
            @click="addSelected"
            title="Assign selected items"
          >
            <feather-icon icon="ArrowRightIcon" size="24" />
          </b-button>
        </div>

        <!-- Allocated Objects aka Linked Objects -->
        <div class="w-50">
          <h6>
            <b-badge variant="info" class="mr-1">
              {{ linkedObjectList.length }}
            </b-badge>
            <span>Linked {{ typeName }}</span>
          </h6>
          <b-input v-model="allocatedFilter" class="mb-1" placeholder="Filter..."></b-input>
          <vue-perfect-scrollbar
            v-if="linkedObjectList.length > 0"
            class="scroll-area"
            :settings="{
              maxScrollbarLength: 60,
              wheelPropagation: false,
            }"
          >
            <b-list-group class="square-top" title="Assigned items">
              <b-list-group-item
                v-for="(lob, index) in filteredLinkedObjectList"
                :key="lob.id"
              >
                <div v-if="filteredLinkedObjectList.includes(lob)" class="d-flex flex-column">
                  <div class="d-flex justify-content-between pb-0">
                    <div class="pt-25 pl-25 mb-0 pb-0 w-100">
                      <slot name="right" v-bind="{cpt: lob, triggerSave}" />
                    </div>
                    <div class="w-5 d-inline-flex flex-row-reverse">
                      <b-button
                        :id="`remove-btn-${lob.id}`"
                        variant="flat-danger"
                        size="sm"
                        class="p-25 ml-3"
                        @click.stop="removeAllocation(lob.id, index)"
                        title="Remove link"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </div>
                  </div>
                </div>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
          <div v-else>
            <p class="mt-1 ml-1 text-muted">
              No linked {{ typeName }}...
            </p>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  computed, onMounted, ref, watch,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Fuse from 'fuse.js'

export default {
  name: 'GenericAssociator',
  /**
   * This form handles generic allocation for different Components.
   * Updates a single or multiple Components.
   * Allows filtering by different parent objects
   * It should not be used directly but should be pre-configured in an intermediate UI component
   * - should accept props to show generic rendering
   * - should render from a slot for selected/linked items
   * For comments in this file, the examples use the following:
   *  - components (items) associated/allocated to a requirement (target) from a particular model (prefilter)
   */
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    VuePerfectScrollbar,
  },
  props: {
    isModal: {
      type: Boolean,
      default: true,
    },
    canShowAll: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String],
      required: true,
    },
    // Array of items that can be assigned to the target
    // not required because can be loaded by fetch-fn
    initialAvailableList: {
      type: Array,
      required: false,
      default: () => [],
    },
    // Array of items that have already been assigned to the target
    initialList: {
      type: Array,
      required: true,
    },
    labelFilterEnabled: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    prefilterEnabled: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    // The text in the dropdown for each prefilter item, e.g. model name
    prefilterLabel: {
      type: String,
      required: false,
      default: 'title',
    },
    prefilterName: {
      type: String,
      required: false,
      default: () => 'Model',
    },
    // The label of the type of items getting allocated to the target
    typeName: {
      type: String,
      required: true,
      default: 'component',
    },
    // A function pointer, called each time the X is clicked for a linked item (e.g. right-side component)
    removeFn: {
      type: Function,
      required: false,
      default: () => false,
    },
    // A function pointer, called each time the assign button (arrow) is clicked
    updateFn: {
      type: Function,
      required: true,
    },
    // A function pointer, called
    // a) initially
    // b) to refresh the left list based on the filter, or
    // c) when Refresh button clicked
    // (e.g. get all components in the current model)
    fetchFn: {
      type: Function,
      required: false,
    },
    // A function pointer called when the prefilter needs to be loaded (e.g. get all models)
    fetchFilterFn: {
      type: Function,
      required: false,
    },
    // Defaults to the currently selected prefilter (e.g. current model)
    initialPrefilter: {
      type: Object,
      required: false,
    },
    // If true, use the update/delete functions immediately on assign/delete clicked
    // Otherwise show the save button and submit a payload of all assigned items
    instantSave: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    // showSave will have no effect if instantSave is true
    showSave: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    // Filter by this property on each allocated object, defaults to 'name'
    filterProperty: {
      type: String,
      required: false,
      default: () => 'name',
    },
    filterProperties: {
      type: Array,
      required: false,
      default: () => [],
    },
    compatibleOptionMode: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    convertItemFn: {
      type: Function,
      required: false,
      default: i => i,
    },
    titleOverride: {
      type: String,
      required: false,
    },
  },
  setup(props, context) {
    // Initial selected item for the prefilter dropdown
    const selectedPreFilterObject = ref(props.initialPrefilter)
    const instantSave = ref(props.instantSave)
    // Source for the prefilter dropdown
    const selectedPreFilterList = ref([])
    const isLoading = ref(false)
    const { filterProperty, filterProperties } = props

    const fetchFilterList = async () => {
      if (props.fetchFilterFn) {
        isLoading.value = true
        selectedPreFilterList.value = await props.fetchFilterFn()
        isLoading.value = false
        if (!selectedPreFilterList.value.includes(props.initialPrefilter)) {
          selectedPreFilterObject.value = selectedPreFilterList.value.find(o => o.id === props.initialPrefilter)
        }
      }
    }

    const selectedList = ref([])
    const availableList = ref(props.initialAvailableList)
    const allDistinctLabels = ref([])
    const selectedLabels = ref([])
    const buildDistinctLabels = sourceList => {
      const tempLabels = []
      if (props.labelFilterEnabled) {
        sourceList.forEach(cpt => {
          tempLabels.push(...cpt.labels.filter(lc => lc !== 'Component' && !lc.startsWith('RBAC_')))
        })
      }
      return [...new Set(tempLabels)]
    }

    const fetchAvailableObjects = async () => {
      if (props.fetchFilterFn) {
        isLoading.value = true
        availableList.value = await props.fetchFn(selectedPreFilterObject?.value?.id)
        allDistinctLabels.value = buildDistinctLabels(availableList.value)
        selectedLabels.value = allDistinctLabels.value
        isLoading.value = false
      } else if (props.fetchFn) {
        isLoading.value = true
        availableList.value = await props.fetchFn()
        allDistinctLabels.value = buildDistinctLabels(availableList.value)
        selectedLabels.value = allDistinctLabels.value
        isLoading.value = false
      }
    }
    const linkedObjectList = ref([])
    const initialList = computed(() => props.initialList)
    const isModalShown = ref(false)
    const onShow = () => {
      linkedObjectList.value = initialList.value.map(props.convertItemFn)
      availableList.value = props.initialAvailableList.map(props.convertItemFn)
      fetchFilterList()
      fetchAvailableObjects()
      isModalShown.value = true
    }
    const onHidden = () => {
      isModalShown.value = false
    }
    const searchText = ref('')
    const allocatedFilter = ref('')
    const removeAllocation = async (itemId, index) => {
      try {
        linkedObjectList.value = linkedObjectList.value.filter(lo => lo.id !== itemId)
        if (instantSave.value && props.removeFn) props.removeFn(itemId, linkedObjectList.value)
      } catch (error) {
        console.error('Failed to delete allocation')
        context.root.$swal({
          icon: 'error',
          title: 'Error removing allocation',
          text: error.response.data.detail,
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })
      }
    }

    const addSelected = () => {
      // Add the item from the left list to the right list if it doesn't already exist.
      // Saves instantly (if enabled)
      // Note: selectedList.value is an array of IDs
      const newItems = []
      selectedList.value.forEach(
        sid => {
          const item = availableList.value.find(y => y.id === sid)
          if (item && linkedObjectList.value.filter(tI => tI.id === sid).length === 0) {
            if (instantSave.value && props.updateFn) props.updateFn(item)
            newItems.push(item)
          }
        },
      )
      linkedObjectList.value.push(...newItems)
    }
    const filteredLinkedObjectList = computed(() => {
      if (allocatedFilter.value === '') {
        return linkedObjectList.value
      }
      if (filterProperties && filterProperties.length > 0) {
        return linkedObjectList.value.filter(lo => filterProperties.some(fp => lo[fp]?.toLowerCase().includes(allocatedFilter.value.toLowerCase())))
      }
      return linkedObjectList.value.filter(lo => lo[filterProperty]?.toLowerCase().includes(allocatedFilter.value.toLowerCase()))
    })

    const triggerSave = () => {
      // triggerSave is passed to the slot for linked objects
      // Only want to fire a save request when instantSave is true,
      // if it's false, the user will have to click the Save button
      if (instantSave.value) {
        onSubmit()
      }
    }

    const onSubmit = () => {
      const payload = linkedObjectList.value
      if (props.updateFn) {
        props.updateFn(payload, selectedLabels)
      }
    }

    // Watchers
    watch(selectedPreFilterObject, () => {
      fetchAvailableObjects()
    })
    watch(() => props.initialAvailableList, () => {
      if (isModalShown.value) {
        availableList.value = props.initialAvailableList.map(props.convertItemFn)
      }
    })
    const currentPageAvailable = ref(1)
    const rows = computed(() => filteredAvailableList.value.length)
    const filteredAvailableList = computed(() => {
      let tempList = []
      if (searchText.value && searchText.value.length > 0) {
        const options = {
          keys: [filterProperty, ...filterProperties],
        }
        const fuse = new Fuse(availableList.value, options)
        const results = fuse.search(searchText.value)
        tempList = results.map(r => r.item)
      } else {
        tempList = availableList.value
      }
      tempList = tempList.filter(li => !linkedObjectList.value.find(ri => li.id === ri.id))
      if (allDistinctLabels.value.length > 0 && selectedLabels.value.length > 0) {
        return tempList.filter(ob => ob.labels?.find(cl => selectedLabels.value.includes(cl)))
      }
      return tempList
    })
    const filteredPagedAvailableList = computed(() => filteredAvailableList.value.slice((currentPageAvailable.value - 1) * 100, ((currentPageAvailable.value - 1) * 100) + 100))

    function labelChanged(label) {
      // not sure why vue-select doesn't deselect tags
      // toggle the tag if it's already in the list (or not)
      if (selectedLabels.value.includes(label)) {
        selectedLabels.value = selectedLabels.value.filter(l => l !== label)
        return
      }
      selectedLabels.value.push(label)
    }

    return {
      // Setup
      linkedObjectList,
      filteredLinkedObjectList,
      selectedPreFilterList,
      availableList,
      filteredAvailableList,
      filteredPagedAvailableList,
      isLoading,
      fetchAvailableObjects,
      currentPageAvailable,
      rows,

      // Selected items
      selectedPreFilterObject,
      selectedList,

      // Form
      searchText,
      addSelected,
      removeAllocation,

      allDistinctLabels,
      selectedLabels,

      // Submit
      onSubmit,
      onShow,
      onHidden,
      isModalShown,

      allocatedFilter,
      triggerSave,
      labelChanged,
    }
  },
}
</script>

<style lang="scss" scoped>
.scroll-area {
  position: relative;
  margin: auto;
  max-height: 50vh;
}
.text-ellipsis {
  max-width: 170rem;
  text-overflow: ellipsis;
  overflow-wrap: normal;
  hyphens: auto;
  overflow: clip;
  display: inline-block;
}

.square-top:first-child {
  border-radius: 0 0 0.358rem 0.358rem;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-sweetalert.scss';
select {
  -webkit-appearance: none;
}
#genericAssociator .v-select .vs__selected-options {
  max-height: 4rem;
  overflow-y: auto;
}
#genericAssociator .v-select .vs__dropdown-option--selected {
  color: #1e1e1e;
}
body.dark-layout {
  .badge.badge-primary {
    color: #1e1e1e;
  }
}
</style>
