export default [
  {
    headerKey: 7,
    header: 'Administration',
  },
  {
    title: 'Users',
    route: 'admin_users',
    icon: 'UserIcon',
  },
  {
    title: 'Announcements',
    route: 'admin_banner',
    icon: 'RadioIcon',
  },
]
