import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAlignLeft,
  faAngleLeft,
  faAngleRight,
  faBezierCurve,
  faBinoculars,
  faBold,
  faBolt,
  faBug,
  faBullseye,
  faChartBar,
  faCircle as fasCircle,
  faCircleNotch,
  faClipboardCheck,
  faClipboardList,
  faCode,
  faCog,
  faCogs,
  faColumns,
  faCube,
  faDesktop,
  faEllipsisH,
  faEllipsisV,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFileCode,
  faFileImport,
  faFolder,
  faHeading,
  faHistory,
  faHome,
  faImage,
  faInfoCircle,
  faItalic,
  faLink,
  faLinkSlash,
  faListOl,
  faListUl,
  faMoneyBill,
  faPalette,
  faPaperPlane,
  faProjectDiagram,
  faQuoteLeftAlt,
  faRedo,
  faRemoveFormat,
  faSatelliteDish,
  faScaleUnbalanced,
  faSearch,
  faSeedling,
  faSitemap,
  faSpinner,
  faStream,
  faStrikethrough,
  faTable,
  faTableCells,
  faTableColumns,
  faTableList,
  faTags,
  faTasks,
  faTint,
  faUnderline,
  faUndo,
  faVectorSquare,
  faVials,
  faWrench,
  faArrowRight,
  faArrowLeft,
  faTimeline,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons'
import { faCircle, faLightbulb, faUserCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Add Font-awesome icons
library.add(
  faAlignLeft,
  faAngleLeft,
  faAngleRight,
  faBezierCurve,
  faBinoculars,
  faBold,
  faBolt,
  faBug,
  faBullseye,
  faChartBar,
  faCircle,
  faCircleNotch,
  faClipboardCheck,
  faClipboardList,
  faCode,
  faCog,
  faCogs,
  faColumns,
  faCube,
  faDesktop,
  faEllipsisH,
  faEllipsisV,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFileCode,
  faFileImport,
  faFolder,
  faHeading,
  faHistory,
  faHome,
  faImage,
  faInfoCircle,
  faItalic,
  faLightbulb,
  faLink,
  faLinkSlash,
  faListOl,
  faListUl,
  faMoneyBill,
  faPalette,
  faPaperPlane,
  faProjectDiagram,
  faQuoteLeftAlt,
  faRedo,
  faRemoveFormat,
  faSatelliteDish,
  faScaleUnbalanced,
  faSearch,
  faSeedling,
  faSitemap,
  faSpinner,
  faStream,
  faStrikethrough,
  faTable,
  faTableCells,
  faTableColumns,
  faTableList,
  faTags,
  faTasks,
  faTint,
  faUnderline,
  faUndo,
  faUserCircle,
  faVectorSquare,
  faVials,
  fasCircle,
  faWrench,
  faArrowRight,
  faArrowLeft,
  faTimeline,
  faQuestion,
)
Vue.component('font-awesome-icon', FontAwesomeIcon)
