import beforeEnter from '@/router/modules/models/beforeEnter'

const routes = [
  {
    path: '/model/:modelId/releases',
    name: 'model_releases',
    component: () => import('@/views/Releases/Releases.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Releases',
      auth: true,
      breadcrumb: [
        {
          text: 'Releases',
          active: true,
        },
      ],
    },
  },
]

export default routes
