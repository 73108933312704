<template>
  <div class="tip-tap-menu">
    <b-button-toolbar>
      <!-- headings -->
      <dropdown>
        <template #label>
          <font-awesome-icon :icon="['fas', 'heading']" />
          eading
        </template>

        <template #items>
          <b-dropdown-item :disabled="disabled" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()">
            <h1 class="m-0">
              H1
            </h1>
          </b-dropdown-item>
          <b-dropdown-item :disabled="disabled" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()">
            <h2 class="m-0">
              H2
            </h2>
          </b-dropdown-item>
          <b-dropdown-item :disabled="disabled" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()">
            <h3 class="m-0">
              H3
            </h3>
          </b-dropdown-item>
          <b-dropdown-item :disabled="disabled" @click="editor.chain().focus().toggleHeading({ level: 4 }).run()">
            <h4 class="m-0">
              H4
            </h4>
          </b-dropdown-item>
          <b-dropdown-item :disabled="disabled" @click="editor.chain().focus().toggleHeading({ level: 5 }).run()">
            <h5 class="m-0">
              H5
            </h5>
          </b-dropdown-item>
          <b-dropdown-item :disabled="disabled" @click="editor.chain().focus().toggleHeading({ level: 6 }).run()">
            <h6 class="m-0">
              H6
            </h6>
          </b-dropdown-item>
          <b-dropdown-item :disabled="disabled" @click="editor.chain().focus().setParagraph().run()">
            <span class="m-0">
              Paragraph
            </span>
          </b-dropdown-item>
        </template>
      </dropdown>
      <!-- ./headings -->

      <divider />
      <menu-button
        tooltip="Bold"
        :class="isActive('bold')"
        :disabled="disabled"
        @click="editor.chain().focus().toggleBold().run()"
      >
        <font-awesome-icon :icon="['fas', 'bold']" />
      </menu-button>
      <menu-button
        tooltip="Italic"
        :class="isActive('italic')"
        :disabled="disabled"
        @click="editor.chain().focus().toggleItalic().run()"
      >
        <font-awesome-icon :icon="['fas', 'italic']" />
      </menu-button>
      <menu-button
        tooltip="Strikethrough"
        :class="isActive('strike')"
        :disabled="disabled"
        @click="editor.chain().focus().toggleStrike().run()"
      >
        <font-awesome-icon :icon="['fas', 'strikethrough']" />
      </menu-button>
      <menu-button
        tooltip="Inline code"
        :class="isActive('code')"
        :disabled="disabled"
        @click="editor.chain().focus().toggleCode().run()"
      >
        <font-awesome-icon :icon="['fas', 'code']" />
      </menu-button>
      <menu-button
        tooltip="Code block"
        :class="isActive('codeBlock')"
        :disabled="disabled"
        @click="editor.chain().focus().toggleCodeBlock().run()"
      >
        <font-awesome-icon :icon="['fas', 'file-code']" />
      </menu-button>
      <menu-button
        tooltip="Block quote"
        :class="isActive('blockquote')"
        :disabled="disabled"
        @click="editor.chain().focus().toggleBlockquote().run()"
      >
        <font-awesome-icon :icon="['fas', 'quote-left-alt']" />
      </menu-button>

      <menu-button
        tooltip="Link"
        :class="{ 'is-active': editor.isActive('link') }"
        :disabled="disabled"
        @click="setLink"
      >
        <font-awesome-icon :icon="['fas', 'link']" />
      </menu-button>
      <menu-button
        tooltip="Unlink"
        :class="{ 'is-active': editor.isActive('link') }"
        :disabled="!editor.isActive('link') || disabled"
        @click="editor.chain().focus().unsetLink().run()"
      >
        <font-awesome-icon :icon="['fas', 'link-slash']" />
      </menu-button>

      <divider />
      <menu-button
        tooltip="Bullet list"
        :class="isActive('bulletList')"
        :disabled="disabled"
        @click="editor.chain().focus().toggleBulletList().run()"
      >
        <font-awesome-icon :icon="['fas', 'list-ul']" />
      </menu-button>
      <menu-button
        tooltip="Numbered list" :class="isActive('orderedList')"
        :disabled="disabled"
        @click="editor.chain().focus().toggleOrderedList().run()"
      >
        <font-awesome-icon :icon="['fas', 'list-ol']" />
      </menu-button>
      <menu-button
        tooltip="Horizontal rule"
        :disabled="disabled"
        @click="editor.chain().focus().setHorizontalRule().run()"
      >
        hr
      </menu-button>

      <!-- table buttons -->
      <divider />
      <menu-button
        tooltip="Insert table"
        :class="isActive('table')"
        :disabled="editor.isActive('table') || disabled"
        @click="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()"
      >
        <font-awesome-icon :icon="['fas', 'table']" />
      </menu-button>
      <dropdown v-if="!tableStyleDropdown" :disabled="tableStyleDropdown">
        <template #label>
          <font-awesome-icon :class="isActive('table') ? 'text-primary' : ''" :icon="['fas', 'table-columns']" />
        </template>
        <template #items>
          <b-dropdown-item
            :disabled="!editor.can().mergeOrSplit() || disabled"
            @click="editor.chain().focus().mergeOrSplit().run()"
          >
            Merge / split cells
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item
            :disabled="!editor.can().toggleHeaderRow() || disabled"
            @click="editor.chain().focus().toggleHeaderRow().run()"
          >
            Header row
          </b-dropdown-item>
          <b-dropdown-item
            :disabled="!editor.can().toggleHeaderColumn() || disabled"
            @click="editor.chain().focus().toggleHeaderColumn().run()"
          >
            Header column
          </b-dropdown-item>
        </template>
      </dropdown>
      <dropdown v-if="!tableLayoutDropdown" :disabled="tableLayoutDropdown">
        <template #label>
          <font-awesome-icon
            :class="isActive('table') ? 'text-primary' : ''"
            :icon="['fas', 'table-list']"
          />
        </template>
        <template #items>
          <b-dropdown-item
            :disabled="!editor.can().addRowBefore() || disabled"
            @click="editor.chain().focus().addRowBefore().run()"
          >
            + Row above
          </b-dropdown-item>
          <b-dropdown-item
            :disabled="!editor.can().addRowAfter() || disabled"
            @click="editor.chain().focus().addRowAfter().run()"
          >
            + Row below
          </b-dropdown-item>
          <b-dropdown-item
            :disabled="!editor.can().addColumnBefore() || disabled"
            @click="editor.chain().focus().addColumnBefore().run()"
          >
            + Column before
          </b-dropdown-item>
          <b-dropdown-item
            :disabled="!editor.can().addColumnAfter() || disabled"
            @click="editor.chain().focus().addColumnAfter().run()"
          >
            + Column after
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item
            :disabled="!editor.can().deleteRow() || disabled"
            @click="editor.chain().focus().deleteRow().run()"
          >
            - Delete row
          </b-dropdown-item>
          <b-dropdown-item
            :disabled="!editor.can().deleteColumn() || disabled"
            @click="editor.chain().focus().deleteColumn().run()"
          >
            - Delete column
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item
            :disabled="!editor.can().deleteTable() || disabled"
            @click="editor.chain().focus().deleteTable().run()"
          >
            Delete table
          </b-dropdown-item>
        </template>
      </dropdown>
      <!-- ./table buttons -->

      <divider />
      <menu-button tooltip="Clear styling" :disabled="disabled" @click="editor.chain().focus().unsetAllMarks().run()">
        <font-awesome-icon :icon="['fas', 'remove-format']" />
      </menu-button>
      <!--<menu-button @click="editor.chain().focus().clearNodes().run()">-->
      <!--  clear nodes-->
      <!--</menu-button>-->
      <menu-button tooltip="Undo" :disabled="disabled" @click="editor.chain().focus().undo().run()">
        <font-awesome-icon :icon="['fas', 'undo']" />
      </menu-button>
      <menu-button tooltip="Redo" class="mr-1" :disabled="disabled" @click="editor.chain().focus().redo().run()">
        <font-awesome-icon :icon="['fas', 'redo']" />
      </menu-button>
      <!-- ./table buttons -->

      <!-- image upload -->
      <b-button-group v-if="allowImageUpload">
        <b-form-file
          size="sm"
          placeholder="Upload an image"
          tooltip="Add Image"
          no-drop
          accept="image/jpeg, image/png, image/gif"
          :disabled="disabled"
          @change="addImage"
        >
          <font-awesome-icon :icon="['fas', 'image']" />
        </b-form-file>
      </b-button-group>

      <!-- ./image upload -->
    </b-button-toolbar>

    <!-- url modal -->
    <!--    <b-modal-->
    <!--      id="tiptap-url-modal"-->
    <!--      title="Link URL"-->
    <!--      ok-title="Save"-->
    <!--      cancel-variant="outline-secondary"-->
    <!--      cancel-title="Close"-->
    <!--      centered-->
    <!--      @ok="setLink"-->
    <!--    >-->
    <!--      <b-form>-->
    <!--        <b-form-group>-->
    <!--          <label for="url">URL</label>-->
    <!--          <b-form-input id="url" v-model="url" type="text" placeholder="Enter a URL" />-->
    <!--        </b-form-group>-->
    <!--      </b-form>-->
    <!--    </b-modal>-->
    <!-- ./url modal -->
  </div>
</template>

<script>
import TipTapDropdown from '@/components/Forms/TipTapEditor/TipTapDropdown.vue'
import TipTapMenuButton from '@/components/Forms/TipTapEditor/TipTapMenuButton.vue'
import TipTapMenuDivider from '@/components/Forms/TipTapEditor/TipTapMenuDivider.vue'

export default {
  name: 'TipTapToolbarMenu',
  components: {
    Dropdown: TipTapDropdown,
    MenuButton: TipTapMenuButton,
    Divider: TipTapMenuDivider,
  },
  props: {
    editor: {
      type: Object,
      required: true,
    },
    allowImageUpload: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    imageFile: null,
  }),
  computed: {
    tableLayoutDropdown() {
      return !this.editor.can().addRowBefore()
        || !this.editor.can().addRowAfter()
        || !this.editor.can().addColumnBefore()
        || !this.editor.can().addColumnAfter()
        || !this.editor.can().deleteRow()
        || !this.editor.can().deleteColumn()
        || !this.editor.can().deleteTable()
    },
    tableStyleDropdown() {
      return !this.editor.can().toggleHeaderRow()
        && !this.editor.can().toggleHeaderColumn()
        && !this.editor.can().mergeOrSplit()
    },
  },
  methods: {
    isActive(item) {
      return this.editor.isActive(item) ? 'is-active' : ''
    },
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href
      const url = window.prompt('Enter the URL:\nNote: Ensure you include \'http://\' if required for external links.', previousUrl)

      // cancelled
      if (url === null) {
        return
      }

      // empty
      if (url === '') {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()

        return
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    },
    addImage(event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onloadend = event => {
        this.editor.chain().focus().setImage({ src: reader.result }).run()
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>

<style lang="scss" scoped>
.tip-tap-menu {
    border: 1px solid #3b4253;
    border-top-left-radius: 0.357rem;
    border-top-right-radius: 0.357rem;
    padding-left: 0.2rem;
}
</style>
