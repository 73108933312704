import beforeEnter from '@/router/modules/models/beforeEnter'

const routes = [
  {
    path: '/model/:modelId/canvas',
    name: 'model_canvas',
    component: () => import('@/views/BRSCanvas/BRSCanvas.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Canvas',
      auth: true,
      breadcrumb: [
        {
          text: 'Canvas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/capabilities',
    name: 'model_capabilities',
    component: () => import('@/views/BRSCanvas/Capabilities.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Capabilities',
      auth: true,
      breadcrumb: [
        {
          text: 'Capabilities',
          active: true,
        },
      ],
    },
  },
]

export default routes
