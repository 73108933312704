import coreService from '@/libs/api-services/core-service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
import router from '@/router'

const getDefaultState = () => ({
  evidenceList: [],
  selectedEvidence: null,
  selectedEvidenceETag: '',
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    SET_EVIDENCE_LIST: (state, data) => { state.evidenceList = data },
    SET_SELECTED_EVIDENCE: (state, data) => { state.selectedEvidence = data },
    SET_SELECTED_ETAG: (state, data) => { state.selectedEvidenceETag = data },
    CLEAR_SELECTED_EVIDENCE_DATA: state => {
      state.selectedEvidence = null
      state.selectedEvidenceETag = ''
    },
    CREATE_EVIDENCE: (state, data) => {
      state.evidenceList = [data, ...state.evidenceList]
    },
    UPDATE_EVIDENCE: (state, data) => {
      const index = state.evidenceList.findIndex(ev => ev.id === data.id)
      const updated = { ...state.evidenceList[index], ...data }
      Object.assign(state.evidenceList[index], updated)
    },
  },
  actions: {
    fetchEvidenceList: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      return coreService.qualificationEvidenceApi
        .listAllQualificationEvidence(modelId)
        .then(data => {
          commit('SET_EVIDENCE_LIST', data)
          return data
        })
        .catch(error => {
          console.error(error)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to fetch evidence list',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    getEvidence: ({ commit }, evidenceId) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'model-id': modelId } }
      return coreService
        .get(`/v1/qualification_evidence/${evidenceId}`, config)
        .then(response => {
          commit('SET_SELECTED_EVIDENCE', response.data)
          commit('SET_SELECTED_ETAG', response.headers.etag)
        })
        .catch(error => {
          console.error(error)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to fetch evidence',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    createEvidence: ({ commit }, data) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'model-id': modelId } }
      return coreService
        .post('/v1/qualification_evidence', data, config)
        .then(response => {
          commit('CREATE_EVIDENCE', response)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Evidence created',
              text: response.title,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          return response
        })
        .catch(error => {
          console.error(error)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create evidence.',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    updateEvidence: ({ state, commit }, payload) => {
      const { modelId } = router.currentRoute.params
      return coreService.qualificationEvidenceApi
        .updateQualificationEvidence(
          state.selectedEvidence.id,
          modelId,
          state.selectedEvidenceETag,
          payload,
        )
        .then(data => {
          commit('UPDATE_EVIDENCE', data)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Evidence updated',
              text: data.title,
              icon: 'Edit2Icon',
              variant: 'success',
            },
          })
          commit('CLEAR_SELECTED_EVIDENCE_DATA')
          return data
        })
        .catch(error => {
          console.error(error)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to update evidence.',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    deleteEvidence: ({ state, commit }) => {
      const { modelId } = router.currentRoute.params
      return coreService.qualificationEvidenceApi
        .deleteQualificationEvidence(state.selectedEvidence.id, modelId, state.selectedEvidenceETag)
        .then(response => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Evidence deleted',
              text: state.selectedEvidence.title,
              icon: 'Trash2Icon',
              variant: 'warning',
            },
          })
          commit('CLEAR_SELECTED_EVIDENCE_DATA')
          return true
        })
        .catch(error => {
          console.error(error)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to delete evidence.',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
