import beforeEnter from '@/router/modules/models/beforeEnter'

const routes = [
  {
    path: '/model/:modelId/specification/:specId',
    name: 'specification_page',
    component: () => import('@/views/Specification/Specification.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Specification',
      dynamicTitle: true,
      auth: true,
      breadcrumb: [
        {
          text: 'Specifications',
          to: {
            name: 'all_specifications_table',
          },
        },
        {
          text: 'Specification',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/specification/:specId/baseline/:ver',
    name: 'specification_baseline_compare',
    component: () => import('@/views/Baseline/BaselineDiffTable.vue'),
    beforeEnter,
    meta: {
      auth: true,
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      pageTitle: 'Compare with Baseline',
      titleHidden: true,
      breadcrumb: [
        {
          text: 'Specifications',
          to: {
            name: 'all_specifications_table',
            params: {
              modelId: sessionStorage.getItem('kompozition-workspace'),
            },
          },
        },
        {
          text: 'Specification',
          to: {
            name: 'specification_page',
          },
        },
        {
          text: 'Baseline',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/specification/:specId/baseline/:ver&?filter=:filter',
    name: 'specification_baseline_compare_filter',
    component: () => import('@/views/Baseline/BaselineDiffTable.vue'),
    beforeEnter,
    meta: {
      auth: true,
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      pageTitle: 'Compare with Baseline',
      titleHidden: true,
      breadcrumb: [
        {
          text: 'Specifications',
          to: {
            name: 'all_specifications_table',
            params: {
              modelId: sessionStorage.getItem('kompozition-workspace'),
            },
          },
        },
        {
          text: 'Specification',
          to: {
            name: 'specification_page',
          },
        },
        {
          text: 'Baseline',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/specification/:specId/snapshot/:snapId',
    name: 'specification_snapshot_compare',
    component: () => import('@/views/Snapshot/SnapshotDiffTable.vue'),
    beforeEnter,
    meta: {
      auth: true,
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      pageTitle: 'Compare with Snapshot',
      titleHidden: true,
      breadcrumb: [
        {
          text: 'Specifications',
          to: {
            name: 'all_specifications_table',
            params: {
              modelId: sessionStorage.getItem('kompozition-workspace'),
            },
          },
        },
        {
          text: 'Specification',
          to: {
            name: 'specification_page',
          },
        },
        {
          text: 'Snapshot',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/specification/:specId/snapshot/:snapId&?filter=:filter',
    name: 'specification_snapshot_compare_filter',
    component: () => import('@/views/Snapshot/SnapshotDiffTable.vue'),
    beforeEnter,
    meta: {
      auth: true,
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      pageTitle: 'Compare with Snapshot',
      titleHidden: true,
      breadcrumb: [
        {
          text: 'Specifications',
          to: {
            name: 'all_specifications_table',
            params: {
              modelId: sessionStorage.getItem('kompozition-workspace'),
            },
          },
        },
        {
          text: 'Specification',
          to: {
            name: 'specification_page',
          },
        },
        {
          text: 'Snapshot',
          active: true,
        },
      ],
    },
  },
]

export default routes
