import { request as __request } from './core/request';
import { BaseHttpRequest } from './core/BaseHttpRequest';
export class KompRequest extends BaseHttpRequest {
    static axiosInstance;
    constructor(config) {
        super(config);
    }
    static setAxiosInstance(instance) {
        KompRequest.axiosInstance = instance;
    }
    request(options) {
        return __request(this.config, options, KompRequest.axiosInstance);
    }
}
