<template>
  <b-modal
    id="raise-issue-modal"
    title="Raise Issue"
    size="lg"
    @show="setIssue"
    @close="closeModal"
    @ok="onSubmit"
  >
    <IssueDetails :issue="issue" />

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="outline-secondary" @click="cancel()">
        Dismiss
      </b-button>

      <b-button variant="success" :disabled="loading" @click="ok()">

        <span v-if="loading">
          <b-spinner small type="grow" />
          Loading...
        </span>

        <span v-else>
          Raise Issue
        </span>
      </b-button>
    </template>

  </b-modal>
</template>

<script>

import store from '@/store'
import { ref } from '@vue/composition-api'
import coreService from '@/libs/api-services/core-service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import IssueDetails from '@/components/Issues/IssueDetails.vue'

export default {
  components: {
    IssueDetails,
  },
  setup(props, context) {
    const loading = ref(false)
    const issue = ref({})

    const setIssue = () => {
      issue.value = {
        name: '',
        description: '',
        classification: store.state.constants.defaultIssueClassification.id,
        severity: store.state.constants.defaultIssueSeverity.id,
        status: store.state.constants.defaultIssueStatus.id,
      }
    }

    const closeModal = () => {
      store.dispatch('issues/setGlobalTarget', {})
      store.dispatch('issues/setUpdateFn', null)
      context.root.$bvModal.hide('raise-issue-modal')
    }

    const onSubmit = () => {
      loading.value = true

      const payload = {
        issue: issue.value,
        target: store.state.issues.target,
      }

      store.dispatch('issues/createIssue', payload)
        .then(issue => {
          loading.value = false
          if (store.state.issues.update_fn) {
            // if multiple targets, parent will handle the associating
            if (store.state.issues.target.id === 'multiple') {
              store.state.issues.update_fn(issue)
            } else {
              store.state.issues.update_fn()
            }
          }
          closeModal()
        })
    }

    return {
      loading,
      issue,

      setIssue,
      closeModal,
      onSubmit,
    }
  },
}
</script>
