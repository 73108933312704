import beforeEnter from '@/router/modules/models/beforeEnter'

const routes = [
  {
    path: '/model/:modelId/ideas',
    name: 'model_ideas',
    component: () => import('@/views/Ideas/Ideas.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Ideas',
      auth: true,
      breadcrumb: [
        {
          text: 'Ideas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/ideas',
    name: 'model_elicit',
    component: () => import('@/views/Ideas/Ideas.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Ideas',
      auth: true,
      breadcrumb: [
        {
          text: 'Ideas',
          active: true,
        },
      ],
    },
  },
]

export default routes
