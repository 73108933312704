<template>
  <div>
    <fieldset class="form-group mb-50">
      <div class="d-flex flex-row">
        <b-avatar
          size="34"
          class="mt-25 mr-25"
        >
          {{ userAcronym || '?' }}
        </b-avatar>
        <b-textarea
          ref="commentBox"
          v-model="comment"
          placeholder="Write a comment..."
          rows="1"
          wrap="soft"
          class="form-control comment-chat-box"
          @keydown.ctrl.enter="handleEnter"
        />
      </div>
    </fieldset>
    <div class="w-100 d-inline-flex flex-row-reverse">
      <button
        type="button"
        :disabled="validComment === 0"
        class="btn btn-info btn-sm mb-50"
        @click="createComment"
      >
        <span>Post comment</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CommentChatBox',
  data() {
    return {
      comment: '',
    }
  },
  computed: {
    ...mapState({
      entity_details: state => state.comments.entity_details,
    }),
    userAcronym() {
      return `${this.$store.state.auth.firstName.charAt(0).toUpperCase()}${this.$store.state.auth.lastName.charAt(0).toUpperCase()}`
    },
    validComment() {
      return this.comment.trim().length
    },
  },
  methods: {
    handleEnter(e) {
      if (this.validComment) {
        if (e.ctrlKey) {
          this.createComment()
        }
      }
    },
    createComment() {
      const message = this.comment.replace(/'/g, "\\'")
      this.$emit('create-comment', message)
      this.comment = ''
    },
  },
}
</script>

<style lang="scss">
.comment-chat-box {
  border-radius: 18px;
}
</style>
