import coreService from '@/libs/api-services/core-service'

export default {
  methods: {
    async fetchUserUsername(userId) {
      if (this.$store.state.user.userCache[userId]) {
        return this.$store.state.user.userCache[userId].username
      }
      const userDetails = await getUserDetails(userId)
      if (userDetails && userDetails.username) {
        this.$store.commit('user/SET_USER_USERNAME', { userId, username: userDetails.username })
        return userDetails.username
      }
      return 'Username not found' // Provide a fallback value if username is not found
    },
    async fetchUsernamesForRecords(records) {
      if (records) {
        const userIds = records.map(record => record.userId).filter(userId => !this.$store.state.user.userCache[userId])
        await Promise.all(userIds.map(userId => this.fetchUserUsername(userId)))
      }
    },
    getUserUsername(userId) {
      if (userId) {
        const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
        const isUsernameAdd = uuidPattern.test(userId)
        if (!isUsernameAdd) {
          return userId
        }
        if (!this.$store.state.user.userCache[userId] || !this.$store.state) {
          // we need to do this to stop the app from looping the same record until the api returns data
          this.$store.commit('user/SET_USER_USERNAME', { userId, username: 'Loading...' })
          const resp = this.fetchUserUsername(userId)
          userId = resp.data
        }
        return this.$store.state.user.userCache[userId] || 'Loading...'
      }
      // Return this, as field may be empty
      return ''
    },

    async getAllUsers() {
      const response = await coreService.get('/auth/users')
      const users = response.data

      await Promise.all(users.map(async user => {
        const userId = user.id
        const userDetails = await getUserDetails(userId)
        if (userDetails && userDetails.username) {
          this.$store.commit('user/SET_USER_USERNAME', { userId, username: userDetails.username })
        }
        return userDetails.username
      }))

      return users
    },
  },
}

/**
 * This service is to grab the user details
 */

export const getUserDetails = async userId => {
  try {
    const response = await coreService.get(`/auth/user/${userId}`)
    return response.data
  } catch (error) {
    console.error(`Failed to fetch user details for ID: ${userId}`, error)
    return null
  }
}
