import beforeEnter from '@/router/modules/models/beforeEnter'

const routes = [
  {
    path: '/model/:modelId/snapshots',
    name: 'manage_model_snapshots',
    component: () => import('@/views/Models/Snapshots.vue'),
    beforeEnter,
    meta: {
      auth: true,
      pageTitle: 'Snapshots',
      breadcrumb: [
        {
          text: 'Snapshots',
          active: true,
        },
      ],
    },
  },
]

export default routes
