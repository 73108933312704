import coreService from '@/libs/api-services/core-service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    selectedETag: '',
    selectedCollectionParent: '',
  },
  getters: {},
  actions: {
    getRootCollection: ({ state }, payload) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'model-id': modelId } }
      return coreService
        .get('/v1/collections/root', config)
        .then(({ data }) => data)
    },
    getCollectionTree: ({ state }, payload) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'model-id': modelId, items: payload.items } }
      return coreService
        .get('/v1/collections/tree', config)
        .then(({ data }) => data)
    },
    getItems: ({ state }, payload) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'parent-id': payload.parent_id, 'model-id': modelId } }
      return coreService
        .get('/v1/collections', config)
        .then(({ data }) => data)
    },
    createCollection: ({ state }, payload) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'model-id': modelId } }
      return coreService
        .post('/v1/collections', payload, config)
        .then(({ data }) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Collection created',
              text: `Created collection: ${data.name}`,
              icon: 'FolderIcon',
              variant: 'success',
            },
          })
        })
    },
    updateCollection: ({ state }, payload) => {
      const config = {
        headers: {
          'parent-id': payload.parent,
          'if-match': state.selectedETag,
        },
      }
      return coreService
        .patch(`/v1/collections/${payload.id}`, payload.data, config)
        .then(({ data }) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Collection updated',
              text: `Updated collection: ${data.name}`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          return data
        })
    },
    deleteCollection: ({ state }, payload) => {
      const { modelId } = router.currentRoute.params
      const config = {
        headers: {
          'model-id': modelId,
          'if-match': state.selectedETag,
        },
      }
      return coreService
        .delete(`/v1/collections/${payload.collId}`, config)
        .then(({ data }) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: data.detail.result === 'success' ? 'Collection deleted' : 'Deletion failed',
              text: data.detail.message,
              icon: 'TrashIcon',
              variant: 'warning',
            },
          })
          return data
        })
    },
    add: ({ state }, payload) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'model-id': modelId } }
      return coreService
        .post('/v1/collections/add', payload, config)
        .then(response => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: response.data,
              icon: 'TickIcon',
              variant: 'success',
            },
          })
        }).catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Could not copy item',
              text: error.response.data.detail,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    move: ({ state }, payload) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'model-id': modelId, 'delete-src': payload.deleteSrc } }
      return coreService
        .post(`/v1/collections/${payload.collection}/move`, payload.targets, config)
        .then(response => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.detail.message,
              icon: 'TickIcon',
              variant: 'success',
            },
          })
        }).catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Could not move collection',
              text: error.response.data.detail,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    removeItemFromCollection: ({ state }, payload) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'model-id': modelId } }
      return coreService
        .post(`/v1/collections/${payload.collId}/remove`, payload.item, config)
        .then(() => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Item removed',
              // text: `Created collection: ${data.name}`,
              icon: 'TickIcon',
              variant: 'warning',
            },
          })
          return true
        })
    },
  },
}
