import axiosIns from '@/libs/axios'
import router from '@/router'

const getDefaultState = () => ({
  reviewsList: [],
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    SET_REVIEWS: (state, data) => { state.reviewsList = data },
    CLEAR_ALL: state => { Object.assign(state, getDefaultState()) },
  },
  actions: {
    clearReviews: ({ commit }) => { commit('CLEAR_ALL') },
    getReviews: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      return axiosIns
        .get('/api/v2/reviews', { params: { model: modelId } })
        .then(({ data }) => {
          commit('SET_REVIEWS', data)
        })
        .catch(e => console.error(e))
    },
  },
}
