const beforeEnter = (to, from, next) => {
  /**
   * This router-guard checks if a Model Workspace Id is set in sessionStorage('kompozition-workspace')
   * If no model_id is found in the sessionStorage('kompozition-workspace') then redirect to an error page.
   * @type {string}
   * @private
   */
  const kompozitionWorkspace = sessionStorage.getItem('kompozition-workspace')
  if (to?.params?.modelId) {
    sessionStorage.setItem('kompozition-workspace', to.params.modelId)
    next()
    return
  }
  if (kompozitionWorkspace) {
    if (!to?.params?.modelId) {
      to.params.modelId = kompozitionWorkspace
    }
    next(to)
  } else {
    console.warn(`[VueRouter/beforeEnter] model_id is not defined. Got: ${kompozitionWorkspace}`)
    next({ name: 'error-no-model' })
  }
}

export default beforeEnter
