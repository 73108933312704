export class QualificationEvidenceApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * List All Qualification Evidence
     * @param modelId
     * @returns QualificationEvidence Successful Response
     * @throws ApiError
     */
    listAllQualificationEvidence(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/qualification_evidence',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Qualification Evidence
     * @param modelId
     * @param requestBody
     * @returns QualificationEvidence Successful Response
     * @throws ApiError
     */
    createQualificationEvidence(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/qualification_evidence',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Qualification Evidence
     * @param id
     * @param modelId
     * @returns QualificationEvidence Successful Response
     * @throws ApiError
     */
    getQualificationEvidence(id, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/qualification_evidence/{id}',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Qualification Evidence
     * @param id
     * @param modelId
     * @param ifMatch
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteQualificationEvidence(id, modelId, ifMatch) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/qualification_evidence/{id}',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
                'if-match': ifMatch,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Qualification Evidence
     * @param id
     * @param modelId
     * @param ifMatch
     * @param requestBody
     * @returns QualificationEvidence Successful Response
     * @throws ApiError
     */
    updateQualificationEvidence(id, modelId, ifMatch, requestBody) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/qualification_evidence/{id}',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
                'if-match': ifMatch,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Qualification Evidence For Record
     * @param recordId
     * @param modelId
     * @param sortBy
     * @param direction
     * @param skip
     * @param limit
     * @param requestBody
     * @returns QualificationEvidence Successful Response
     * @throws ApiError
     */
    getQualificationEvidenceForRecord(recordId, modelId, sortBy = 'Created', direction = 'DESC', skip, limit, requestBody) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/qualification_evidence/record/{record_id}',
            path: {
                'record_id': recordId,
            },
            headers: {
                'model-id': modelId,
            },
            query: {
                'sortBy': sortBy,
                'direction': direction,
                'skip': skip,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
