import DOMPurify from 'dompurify'

export default {
  // Vue Hook Function
  inserted(el, binding) {
    const { value } = binding
    el.innerHTML = DOMPurify.sanitize(value)
  },
  update(el, binding) {
    const { value } = binding
    el.innerHTML = DOMPurify.sanitize(value)
  },
}
