import axiosIns from '@/libs/axios'
import router from '@/router'

const getDefaultState = () => ({
  ideas: [],
  selectedIdea: false,
  sources: [],
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    SET_IDEAS: (state, ideas) => { state.ideas = ideas },
    SET_SELECTED_IDEA: (state, idea) => { state.selectedIdea = idea },
    SET_SOURCES: (state, sources) => { state.sources = sources },
    CLEAR_ALL: state => { Object.assign(state, getDefaultState()) },
  },
  actions: {
    clearIdeas: ({ commit }) => { commit('CLEAR_ALL') },
    fetchIdeas: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return axiosIns
        .get('/api/v2/ideas', { params })
        .then(({ data }) => commit('SET_IDEAS', data))
        .catch(e => console.error(e))
    },
    createIdea: ({ dispatch }, data) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return axiosIns
        .post('/api/v2/ideas', data, { params })
        .then(() => dispatch('fetchIdeas'))
        .catch(e => console.error(e))
    },
    selectIdea: ({ commit, dispatch }, id) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return axiosIns
        .get(`/api/v2/ideas/${id}`, { params })
        .then(({ data }) => commit('SET_SELECTED_IDEA', data))
        .catch(e => console.error(e))
    },
    updateIdea: ({ dispatch }, data) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return axiosIns
        .put(`/api/v2/ideas/${data.id}`, data, { params })
        .then(() => dispatch('fetchIdeas'))
        .catch(e => console.error(e))
    },
    deleteIdea: ({ dispatch }, id) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return axiosIns
        .delete(`/api/v2/ideas/${id}`, { params })
        .then(() => dispatch('fetchIdeas'))
        .catch(e => console.error(e))
    },
    syncSources: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return axiosIns
        .get('/api/v2/ideas/sources', { params })
        .then(({ data }) => commit('SET_SOURCES', data))
        .catch(e => console.error(e))
    },
  },
}
