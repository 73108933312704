import Vue from 'vue'

// Omit the 's' if an array has only 1 item.
Vue.filter('handlePluralSuffix', el => {
  if (Array.isArray(el)) {
    return el.length === 1 ? '' : 's'
  }
  if (Number.isInteger(el)) {
    return el === 1 ? '' : 's'
  }
  console.warn(`language.js::handlePluralSuffix : Invalid type for el, got ${typeof el}: ${el}`)
  return ''
})

Vue.filter('capitalizeEachWord', value => {
  if (!value) return ''
  return value.replace(/\b\w/g, char => char.toUpperCase())
})

Vue.filter('capitalizeUnderscoredEachWord', value => {
  if (!value) return ''
  return value.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())
})

Vue.filter('addTrailingSpace', value => {
  if (!value) return ''
  return value.endsWith(' ') ? value : `${value} `
})

Vue.filter('truncate', (value, length) => {
  if (!value || typeof value !== 'string') return ''
  if (value.length <= length) return value

  return `${value.substring(0, length)}...`
})
