import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
import axios from 'axios'
import updateToken from '@/auth/middleware/updateToken'
import { KompRequest } from '@/libs/api-core-client/KompRequest';
import { KompCoreApiClient } from '@/libs/api-core-client/KompCoreApiClient'
import store from '@/store'

const __showToast = (title, text, timeout = 3700) => {
  Vue.$toast({
    component: ToastificationContent,
    props: {
      title,
      text,
      icon: 'AlertCircleIcon',
      variant: 'danger',
    },
  }, {
    timeout,
  })
}


let coreService = {}
coreService = axios.create({})
coreService.defaults.baseURL = `${window.location.protocol}//core.${window.location.host}`
KompRequest.setAxiosInstance(coreService)
const client = new KompCoreApiClient({ BASE: coreService.defaults.baseURL }, KompRequest)

// const config = new coreapi.Configuration({})
// // axios auto-serializes the body when the .data request parameter is used.
// // Set this to return false so that it doesn't get JSONified twice
// config.isJsonMime = () => false;


coreService.adminCliApi = client.adminCli
coreService.userManagementApi = client.userManagement
coreService.usersManagementApi = client.usersManagement
coreService.groupManagementApi = client.groupManagement
coreService.roleManagementApi = client.roleManagement
coreService.authenticationFlowApi = client.authenticationFlow

coreService.contentApi = client.content
coreService.componentApi = client.component
coreService.qualificationEvidenceApi = client.qualificationEvidence
coreService.qualificationRecordsApi = client.qualificationRecords
coreService.specificationsApi = client.specifications
coreService.requirementsApi = client.requirements
coreService.tradeStudiesApi = client.tradeStudies
coreService.componentApi = client.component
coreService.modelApi = client.model
coreService.noteApi = client.note
coreService.classDiagramApi = client.classDiagrams
coreService.behavioursApi = client.behaviours


// Request Interceptor
coreService.interceptors.request.use(
  async request => {
    const token = await updateToken()
    request.headers.Authorization = `Bearer ${token}`
    if(store.state.model.database || sessionStorage.getItem("X-Target")){
      request.headers['X-Target'] = store.state.model.database ? store.state.model.database : sessionStorage.getItem("X-Target")
      store.state.model.database = sessionStorage.getItem("X-Target") ? sessionStorage.getItem("X-Target") : store.state.model.database
    }
    return request
  },
  error => {
    console.debug(error)
    return Promise.reject(error)
  },
)

// Response Interceptor
coreService.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      console.error(error.response)

      let toastTitle = `Error ${error.response.status}`
      let toastMsg = error.response.statusText

      if (error.response.status === 412) {
        // E-Tag does not match
        toastTitle = `${toastTitle}: ${error.response.statusText}`
        toastMsg = (
          'The artifact has recently been updated by someone else.'
          + '\nPlease verify their changes and try again.'
        )
        __showToast(toastTitle, toastMsg, 8000)
      } else if (error.response.status === 422) {
        // Data does not conform
        toastTitle = 'Error 422: Unprocessable Entity'
        toastMsg = `${error.response.data.detail[0].msg} for ${error.response.data.detail[0].loc[1]}`
        __showToast(toastTitle, toastMsg, 15000)
      } else {
        // __showToast(toastTitle, toastMsg)
      }
    } else if (error.request) {
      console.debug(error.request)
      console.debug(error.request.statusText)
      console.debug(error.request.status)

      let toastTitle = `Error ${error.request.status}`
      let toastMsg = error.request.statusText

      if (error.request.status === 0) {
        toastTitle = `${toastTitle}: No response`
        toastMsg = 'Core-Service API is not responding.'
      }
      // __showToast(toastTitle, toastMsg)
    }
    return Promise.reject(error)
  },
)

Vue.prototype.$coreService = coreService

export default coreService
