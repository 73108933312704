export class CollectionsApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get Root Collection
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getRootCollection(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/collections/root',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Collection Items
     * @param parentId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getCollectionItems(parentId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/collections',
            headers: {
                'parent-id': parentId,
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Collection
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createCollection(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/collections',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Collection Tree
     * @param modelId
     * @param items
     * @returns any Successful Response
     * @throws ApiError
     */
    getCollectionTree(modelId, items) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/collections/tree',
            headers: {
                'model-id': modelId,
                'items': items,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Collection
     * @param id
     * @param parentId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateCollection(id, parentId, requestBody) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/collections/{id}',
            path: {
                'id': id,
            },
            headers: {
                'parent-id': parentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Collection
     * @param collectionId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteCollection(collectionId, modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/collections/{collection_id}',
            path: {
                'collection_id': collectionId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Move Collection
     * @param collectionId
     * @param modelId
     * @param deleteSrc
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    moveCollection(collectionId, modelId, deleteSrc, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/collections/{collection_id}/move',
            path: {
                'collection_id': collectionId,
            },
            headers: {
                'model-id': modelId,
                'delete-src': deleteSrc,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add To Collection
     * @param modelId
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    addToCollection(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/collections/add',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove From Collection
     * @param collectionId
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    removeFromCollection(collectionId, modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/collections/{collection_id}/remove',
            path: {
                'collection_id': collectionId,
            },
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
