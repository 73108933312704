import axiosIns from '@/libs/axios'

const getDefaultState = () => ({
  selected_comment: {},
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    SELECT_COMMENT: (state, selectedEntityComment) => { state.selected_comment = selectedEntityComment },
    CLEAR_ALL: state => { Object.assign(state, getDefaultState()) },
  },
  actions: {
    clearComments: ({ commit }) => { commit('CLEAR_ALL') },
    getEntityComments: ({ commit }, data) => {
      const parentId = data.id
      const parentLabel = data.label
      return axiosIns
        .get('/api/v2/comments', { params: { label: parentLabel, id: parentId } })
        .then(({ data }) => data)
        .catch(e => console.error(e))
    },
    selectComment: ({ commit }, comment) => {
      commit('SELECT_COMMENT', comment)
    },
  },
}
