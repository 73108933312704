<template>
  <generic-associator :id="`associator-generic-${name.toLowerCase().replace(/\s/g , '-')}${suffix}`"
                      :update-fn="submitAssociatedItems"
                      :type-name="name"
                      :initial-available-list="localAllItems2"
                      :initial-list="localAssociatedItems"
                      filter-property="text"
                      :compatible-option-mode="true"
                      :prefilter-enabled="false"
                      :label-filter-enabled="false"
                      :convert-item-fn="i => ({ ...i.value, text: i.text })"
                      :instant-save="false">
    <template #left="cpt">
      <p :title="cpt.text" class="ml-1 mr-1 text-ellipsis">{{ cpt.text }} </p>
    </template>
    <template #right="{ cpt }">
      <p :title="cpt.text" class="ml-1 mr-1 text-ellipsis">{{ cpt.text }} </p>
    </template>
  </generic-associator>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'
import GenericAssociator from '@/components/Forms/GenericAssociator.vue'

export default {
  name: 'AssociatorGeneric',
  components: { GenericAssociator },
  // Props must be provided for the associator to work
  // Associator must not send the API call to associate, but to return an array of items *to* associate
  props: {
    allItems: {
      required: true,
      type: Array,
    },
    associatedItems: {
      required: true,
      type: Array,
    },
    name: {
      type: String,
      default: 'Items',
    },
    suffix: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    // Declare the variables to be used in setup code
    // -- All Items
    const localAllItems = ref([])
    const localAllItems2 = computed(() => props.allItems)
    const selectedAllItems = ref([])
    watch(() => props.allItems, () => {
      localAllItems.value = [...props.allItems]
    })

    // -- Associated Items
    const localAssociatedItems = ref([])
    const selectedAssociatedItems = ref([])
    watch(() => props.associatedItems, () => {
      localAssociatedItems.value = [...props.associatedItems]
    }, { immediate: true })

    // Functions/Methods
    // -- Sort at least once
    localAllItems.value.sort(sortArrayByDisplayID)
    localAssociatedItems.value.sort(sortArrayByDisplayID)
    // filterOutDuplicateItems()
    // -- Move items
    function add() {
      selectedAllItems.value.forEach((elementA, indexA) => {
        localAllItems.value.forEach((elementB, indexB) => {
          if (elementA === elementB.value) {
            localAllItems.value.splice(indexB, 1)
            localAssociatedItems.value.push(elementB)
          }
        })
      })
      selectedAllItems.value = []
      localAssociatedItems.value.sort(sortArrayByDisplayID)
    }

    function remove() {
      selectedAssociatedItems.value.forEach((elementA, indexA) => {
        localAssociatedItems.value.forEach((elementB, indexB) => {
          if (elementA === elementB.value) {
            localAssociatedItems.value.splice(indexB, 1)
            localAllItems.value.push(elementB)
          }
        })
      })
      selectedAssociatedItems.value = []
      localAllItems.value.sort(sortArrayByDisplayID)
    }

    // -- Sort Array
    function sortArrayByDisplayID(a, b) {
      if (!b) return 0
      const first = a?.value?.toSortBy ? a?.value?.toSortBy : a?.text
      const second = b?.value?.toSortBy ? b?.value?.toSortBy : b?.text
      if (first < second) {
        return -1
      }
      if (first > second) {
        return 1
      }
      return 0
    }

    // -- Filter Arrays
    function filterOutDuplicateItems() {
      localAssociatedItems.value.forEach((elementA, indexA) => {
        // console.log('Element A: ', elementA)
        localAllItems.value.forEach((elementB, indexB) => {
          // console.log('Element B: ', elementA)
          if (elementA.value.id === elementB.value.id) {
            // console.log('Deleted')
            localAllItems.value.splice(indexB, 1)
          }
        })
      })
    }

    // -- Submit
    function submitAssociatedItems(associatedItems) {
      const payload = associatedItems.map(i => i.value?.id || i.id)
      emit('associated', payload)
    }

    // -- Cancel
    function resetFields() {
      localAllItems.value = [...props.allItems]
      localAssociatedItems.value = [...props.associatedItems]
    }

    return {
      // Variables
      // -- Local instances of props
      localAllItems,
      localAllItems2,
      localAssociatedItems,
      // -- Items to be moved from one array to the other
      selectedAllItems,
      selectedAssociatedItems,
      // Functions/Methods
      // -- Filter
      filterOutDuplicateItems,
      // -- Move items
      add,
      remove,
      // -- Submit
      submitAssociatedItems,
      // -- Cancel
      resetFields,
    }
  },
}
</script>
