export default {
  INIT: 0,
  LOADING: 1,
  ERROR: 2,
  LOADED: 3,
  UPDATING: 4,
}

export const apiStatus = {
  INIT: 0,
  LOADING: 1,
  ERROR: 2,
  LOADED: 3,
  UPDATING: 4,
}

export const authStatus = {
  AUTHENTICATED: 'authenticated',
  DENIED: 'denied',
  INIT: 'init',
}

export const navStatus = {
  INIT: 'init',
  IN_MODEL: 'in_model',
}
