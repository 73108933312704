<template>
  <b-list-group-item
    class="entity"
    button
    @click.stop="openRequirement()"
  >
    <h6 class="text-primary">
      {{ req.display_id }}
      <b-badge class="mx-2 float-right">
        {{ req.priority }}
      </b-badge>
    </h6>
    <div class="font-small-3" v-sanitized-html="sanitizedObjectText" />
  </b-list-group-item>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    req: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('api', ['requirements']),
    sanitizedObjectText() {
      return (this.req.object_text || this.req.text || '').replace(/<\/?[^>]+(>|$)/g, '')
    },
  },
  methods: {
    openRequirement() {
      this.$bvModal
        .msgBoxConfirm('Are you sure you wish to leave this page? Any unsaved changes will be lost.', {
          title: 'Open Requirement',
          size: 'sm',
          okVariant: 'outline-danger',
          okTitle: 'Open Requirement',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$router.push(
              {
                name: 'requirements_table',
                params: { specId: this.req.spec_id },
                query: { selectedRequirement: this.req.id },
              },
            )
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-context.scss';

.entity {
  background-color: rgba(22, 29, 49, 0.02) !important;
  border: 1px dashed;
  border-left: 0;
  border-right: 0;
}

.entity:hover {
  cursor: pointer;
  background-color: rgba(22, 29, 49, 0.05) !important;
}

.dark-layout {
  .entity {
    background-color: rgba(22, 29, 49, 0.3) !important;
  }

  .entity:hover {
    background-color: rgba(22, 29, 49, 1) !important;
  }
}
</style>
