export class IssuesApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get Issue
     * @param issueId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getIssue(issueId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/issues/{issue_id}',
            path: {
                'issue_id': issueId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Issue
     * @param issueId
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateIssue(issueId, modelId, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/issues/{issue_id}',
            path: {
                'issue_id': issueId,
            },
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Issues
     * @param modelId
     * @param simple
     * @returns any Successful Response
     * @throws ApiError
     */
    getIssues(modelId, simple = false) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/issues',
            headers: {
                'simple': simple,
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Raise Issue
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    raiseIssue(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/issues',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Linked Issues
     * @param nodeId
     * @param label
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getLinkedIssues(nodeId, label, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/issues/linked/{node_id}',
            path: {
                'node_id': nodeId,
            },
            headers: {
                'label': label,
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Allocate Issue
     * @param issueId
     * @param label
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    allocateIssue(issueId, label, modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/issues/allocate/{issue_id}',
            path: {
                'issue_id': issueId,
            },
            headers: {
                'label': label,
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Allocate Issues
     * @param targetId
     * @param label
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    allocateIssues(targetId, label, modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/issues/bulk_allocate/{target_id}',
            path: {
                'target_id': targetId,
            },
            headers: {
                'label': label,
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Issue Report
     * @param filetype
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createIssueReport(filetype, modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/issues/report',
            headers: {
                'model-id': modelId,
            },
            query: {
                'filetype': filetype,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
