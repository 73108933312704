const state = {
  userCache: {},
}

const mutations = {
  SET_USER_USERNAME(state, { userId, username }) {
    state.userCache = {
      ...state.userCache,
      [userId]: username,
    }
  },
}

const actions = {
  // Your actions here
}

const getters = {
  // Your getters here
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
