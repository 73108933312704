export class ContentApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Content
     * Return the file content or a 404 error if it doesn't exist
 * 'path' convertor returns the rest of the path, including any additional / characters.
 * :param request: HTTP Request
 * :param file: Path to the file
 * :return: File content or 404 error
     * @param file
     * @returns any Successful Response
     * @throws ApiError
     */
    content(file) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/storage/{file}',
            path: {
                'file': file,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
