import beforeEnter from '@/router/modules/models/beforeEnter'

const routes = [
  {
    path: '/model/:modelId/metrics',
    name: 'model_metrics',
    component: () => import('@/views/Metrics/Metrics.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Metrics',
      auth: true,
      breadcrumb: [
        {
          text: 'Metrics',
          active: true,
        },
      ],
    },
  },
]

export default routes
