export class AuthenticationFlowApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Login
     * @param username
     * @param password
     * @returns any Successful Response
     * @throws ApiError
     */
    login(username, password) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/login',
            query: {
                'username': username,
                'password': password,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Login Redirect
     * @returns any Successful Response
     * @throws ApiError
     */
    loginRedirect() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/login-link',
        });
    }
    /**
     * Callback
     * @param sessionState
     * @param code
     * @returns any Successful Response
     * @throws ApiError
     */
    callback(sessionState, code) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/callback',
            query: {
                'session_state': sessionState,
                'code': code,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Logout
     * @returns any Successful Response
     * @throws ApiError
     */
    logout() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/logout',
        });
    }
    /**
     * Validate Token
     * @param authorization
     * @returns any Successful Response
     * @throws ApiError
     */
    validateToken(authorization) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/validate-token',
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
