<template>
  <div class="tip-tap-container">
    <tip-tap-toolbar-menu v-if="editor" :editor="editor" :disabled="disabled" :allow-image-upload="allowImageUpload" class="py-25" />
    <editor-content :editor="editor" class="tip-tap-editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import TipTapToolbarMenu from '@/components/Forms/TipTapEditor/TipTapToolbarMenu.vue'
import Highlight from '@tiptap/extension-highlight'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import StarterKit from '@tiptap/starter-kit'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import TextAlign from '@tiptap/extension-text-align'
import Typography from '@tiptap/extension-typography'

export default {
  components: {
    TipTapToolbarMenu,
    EditorContent,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Compose something epic...',
    },
    allowImageUpload: {
      type: Boolean,
      required: false,
      default: true,
    },
    minHeight: {
      type: String,
      default: '3',
    },
    maxHeight: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    editor: null,
  }),
  computed: {
    cssMinHeight() {
      return this.minHeight !== '' ? `min-height: ${this.minHeight}rem; ` : ''
    },
    cssMaxHeight() {
      return this.maxHeight !== '' ? `max-height: ${this.maxHeight}rem; ` : ''
    },
  },
  watch: {
    cssMaxHeight(value) {
      this.setEditorHeight()
    },
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
    disabled(value) {
      this.editor.setEditable(!value)
    },
  },
  mounted() {
    this.editor = new Editor({
      editorProps: {
        attributes: {
          style: `${this.cssMinHeight}${this.cssMaxHeight} overflow: scroll; overflow-x: hidden;`,
        },
      },
      content: this.value,
      extensions: [
        StarterKit.configure({
          blockquote: {
            HTMLAttributes: {
              class: 'blockquote pl-1 border-left-info border-left-3',
            },
          },
        }),
        Placeholder.configure({
          placeholder: this.placeholder,
        }),
        Highlight,
        Typography,
        TextAlign,
        Image.configure({
          inline: false,
          allowBase64: true,
        }),
        Table.configure({
          // resizable: true,
          HTMLAttributes: {
            class: 'v-html-table',
          },
        }),
        TableRow,
        TableHeader,
        // Default TableCell
        TableCell,
        // URL links
        Link.configure({
          autolink: true,
          linkOnPaste: true,
          openOnClick: true,
        }),
        // Custom TableCell with backgroundColor attribute
        // CustomTableCell,
      ],
      onUpdate: () => {
        // HTML
        this.$emit('input', this.editor.getHTML())

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    })
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  methods: {
    setEditorHeight() {
      this.editor.setOptions({
        editorProps: {
          attributes: {
            style: `${this.cssMinHeight}${this.cssMaxHeight} overflow: scroll; overflow-x: hidden;`,
          },
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@/@core/scss/base/colors.scss';

.tip-tap-container {
  cursor: initial;
  .tip-tap-editor {
    .ProseMirror {
      padding: 0.75rem;
      border: 1px solid #3b4253;
      border-top: none;
      border-bottom-left-radius: 0.357rem;
      border-bottom-right-radius: 0.357rem;

      // Placeholder
      p.is-editor-empty:first-child::before {
        content: attr(data-placeholder);
        float: left;
        color: #adb5bd;
        pointer-events: none;
        height: 0;
      }

      p {
        margin-bottom: 0;
      }
      img {
        max-width: 100%;
        height: auto;
      }
      table {
        .selectedCell:after {
          z-index: 2;
          position: absolute;
          content: "";
          left: 0; right: 0; top: 0; bottom: 0;
          background: rgba($info, 0.4);
          pointer-events: none;
        }

        .column-resize-handle {
          position: absolute;
          right: -2px;
          top: 0;
          bottom: -2px;
          width: 2px;
          background-color: #adf;
          pointer-events: none;
        }
      }
    }

    .tableWrapper {
      padding: 1rem 0;
      overflow-x: auto;
    }

    .resize-cursor {
      cursor: ew-resize;
      cursor: col-resize;
    }
  }
}
</style>
