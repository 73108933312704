export class SpecificationsApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * List Specifications
     * @param modelId
     * @returns Specification Successful Response
     * @throws ApiError
     */
    listSpecifications(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Specification
     * @param modelId
     * @param requestBody
     * @returns Specification Successful Response
     * @throws ApiError
     */
    createSpecification(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/specifications',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Custom Properties
     * @param specId
     * @param modelId
     * @param returnAllFields
     * @returns any Successful Response
     * @throws ApiError
     */
    getCustomProperties(specId, modelId, returnAllFields = false) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}/custom_properties',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
                'return-all-fields': returnAllFields,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Custom Property
     * @param specId
     * @param modelId
     * @param requestBody
     * @param returnAllFields
     * @returns any Successful Response
     * @throws ApiError
     */
    updateCustomProperty(specId, modelId, requestBody, returnAllFields = false) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/specifications/{spec_id}/custom_properties',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
                'return-all-fields': returnAllFields,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Custom Property
     * @param specId
     * @param modelId
     * @param requestBody
     * @param returnAllFields
     * @returns any Successful Response
     * @throws ApiError
     */
    addCustomProperty(specId, modelId, requestBody, returnAllFields = false) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/specifications/{spec_id}/custom_properties',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
                'return-all-fields': returnAllFields,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Custom Property
     * @param specId
     * @param modelId
     * @param requestBody
     * @param returnAllFields
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteCustomProperty(specId, modelId, requestBody, returnAllFields = false) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/specifications/{spec_id}/custom_properties',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
                'return-all-fields': returnAllFields,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * List Specifications Metadata
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    listSpecificationsMetadata(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/metadata',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification
     * @param specId
     * @param modelId
     * @returns Specification Successful Response
     * @throws ApiError
     */
    getSpecification(specId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Specification
     * @param specId
     * @param modelId
     * @param ifMatch
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteSpecification(specId, modelId, ifMatch) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/specifications/{spec_id}',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
                'if-match': ifMatch,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Specification
     * @param specId
     * @param modelId
     * @param ifMatch
     * @param requestBody
     * @returns Specification Successful Response
     * @throws ApiError
     */
    updateSpecification(specId, modelId, ifMatch, requestBody) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/specifications/{spec_id}',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
                'if-match': ifMatch,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirements V2
     * @param specId
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirementsV2(specId, modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/specifications/{spec_id}/requirementsv2',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Issues
     * @param specId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationIssues(specId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}/issues',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Behaviours
     * @param specId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationBehaviours(specId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}/behaviours',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Tests
     * Get all test cases linked to a requirement in the given specification.
 * Data is returned as a dictionary map: { <reqId>: [tests], ... }
     * @param specId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationTests(specId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}/tests',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Releases
     * Get all releases linked to a requirement in the given specification.
 * Data is returned as a dictionary map: { <reqId>: [releases], ... }
     * @param specId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationReleases(specId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}/releases',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Qualification Records
     * Get all Qualification Records linked to a requirement in the given specification.
 * Data is returned as a dictionary map: { <reqId>: [qualification_records], ... }
     * @param specId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationQualificationRecords(specId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}/qualification_records',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Interfaces
     * Get all interfaces linked to a requirement in the given specification.
 * Data is returned as a dictionary map: { <reqId>: [interfaces], ... }
     * @param specId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationInterfaces(specId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}/interfaces',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Components
     * Get all components linked to a requirement in the given specification.
 * Data is returned as a dictionary map: { <reqId>: [components], ... }
     * @param specId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationComponents(specId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}/components',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Trace
     * @param specId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationTrace(specId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}/trace',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Coverage
     * @param specId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationCoverage(specId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}/coverage',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Notes
     * Get all test cases linked to a requirement in the given specification.
 * Data is returned as a dictionary map: { <reqId>: [tests], ... }
     * @param specId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getSpecificationNotes(specId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}/notes',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Export Specification
     * Export the given specification.
     * @param specId
     * @param fileFormat
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    exportSpecification(specId, fileFormat, modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/specifications/{spec_id}/export',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            query: {
                'file_format': fileFormat,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Specification Columns
     * @param specId
     * @param modelId
     * @param onlyCustomAttributes
     * @returns string Successful Response
     * @throws ApiError
     */
    getSpecificationColumns(specId, modelId, onlyCustomAttributes = false) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/specifications/{spec_id}/columns',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            query: {
                'only_custom_attributes': onlyCustomAttributes,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirements
     * @param specId
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirements(specId, modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/specifications/{spec_id}/requirements',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Copy Specification
     * @param specId
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    copySpecification(specId, modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/specifications/{spec_id}/copy',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Specification Snapshot
     * @param specId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createSpecificationSnapshot(specId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/specifications/{spec_id}/snapshot/create',
            path: {
                'spec_id': specId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Rollback Spec To Snapshot
     * @param specId
     * @param snapshotId
     * @returns any Successful Response
     * @throws ApiError
     */
    rollbackSpecToSnapshot(specId, snapshotId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/specifications/{spec_id}/snapshot/{snapshot_id}/rollback',
            path: {
                'spec_id': specId,
                'snapshot_id': snapshotId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Snapshot
     * @param specId
     * @param snapshotId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteSnapshot(specId, snapshotId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/specifications/{spec_id}/snapshot/{snapshot_id}',
            path: {
                'spec_id': specId,
                'snapshot_id': snapshotId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
