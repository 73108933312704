export class TradeStudiesApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get Weights
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    getWeights(formData) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/trade_studies/get_weights',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Trade Studies
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getTradeStudies(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/trade_studies',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Trade Study
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getTradeStudy(id, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/trade_studies/{id}',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Save Trade Study
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    saveTradeStudy(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/trade_studies/save_ts',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Criteria
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getCriteria(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/trade_studiescriteria',
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
