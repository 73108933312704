import beforeEnter from '@/router/modules/models/beforeEnter'

const routes = [
  {
    path: '/model/:modelId/node-history/:nodeId',
    name: 'node_history',
    component: () => import('@/views/History/NodeHistory.vue'),
    beforeEnter,
    meta: {
      auth: true,
      pageTitle: 'History',
      breadcrumb: [
        {
          text: 'History',
          active: true,
        },
      ],
    },
  },
]

export default routes
