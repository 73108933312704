import store from '@/store'

const beforeEnter = (to, from, next) => {
  /**
   * This router-guard checks if a user has permissions to view the user pages, otherwise redirect them to the model browser page.
   * @type {string}
   * @private
   */
  const isAdmin = store.state.auth.roles?.includes('administrator') || false
  const isAllowed = store.state.auth.id === to.params.userId || isAdmin

  if (isAllowed) {
    next()
  } else {
    next({ name: 'model_browse' })
  }
}

const routes = [
  {
    path: '/account/:userId',
    name: 'user_profile',
    component: () => import('@/auth/views/admin/UserManagement/UserProfile.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'User Profile',
      auth: true,
      breadcrumb: [
        {
          text: 'User Profile',
          active: true,
        },
      ],
    },
  },
]

export default routes
