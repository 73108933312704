import beforeEnter from '@/router/modules/models/beforeEnter'

const routes = [
  {
    path: '/model/:modelId/specification/:specId/requirements-legacy/:focus?',
    name: 'legacy_requirements_table_focus_on_id',
    component: () => import('@/views/RequirementsTableLegacy/LegacyRequirementsTable.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Requirements (Legacy)',
      auth: true,
      breadcrumb: [
        {
          text: 'Specifications',
          to: {
            name: 'all_specifications_table',
          },
        },
        {
          text: 'Requirements (Legacy)',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/specification/:specId/requirements-legacy',
    name: 'legacy_requirements_table',
    component: () => import('@/views/RequirementsTableLegacy/LegacyRequirementsTable.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Requirements (Legacy)',
      auth: true,
      breadcrumb: [
        {
          text: 'Specifications',
          to: {
            name: 'all_specifications_table',
          },
        },
        {
          text: 'Requirements (Legacy)',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/specification/:specId/requirement/:requirementId',
    name: 'requirement_detail',
    component: () => import('@/views/Requirement/Requirement.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Requirement',
      auth: true,
      breadcrumb: [
        {
          text: 'Requirements',
          to: {
            name: 'legacy_requirements_table',
          },
        },
        {
          text: 'Requirement',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/specification/:specId/requirements_legacy_pdf',
    name: 'requirements_pdf',
    component: () => import('@/views/RequirementsTableLegacy/RequirementsHTML2PDF.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Spec 2 PDF',
      auth: true,
      breadcrumb: [
        {
          text: 'Specification Export (PDF)',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/evidence',
    name: 'model_evidence',
    component: () => import('@/views/OQE/Evidence.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Qualification Evidence',
      auth: true,
      breadcrumb: [
        {
          text: 'Qualification Evidence',
          active: true,
        },
      ],
    },
  },
]

export default routes
