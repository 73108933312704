import axiosIns from '@/libs/axios'
import { apiStatus } from '@/enums'
import router from '@/router'

const getDefaultState = () => ({
  tests: [],
  pageNumber: 1,
  tests_status: undefined,
  total_count: 0,
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    SET_TESTS: (state, tests) => { state.tests = tests },
    SET_TESTS_TOTAL: (state, totalCount) => { state.total_count = totalCount },
    SET_TESTS_STATUS: (state, testsStatus) => { state.tests_status = testsStatus },
    SET_PAGE_NUMBER: (state, pageNumber) => { state.pageNumber = pageNumber },
    CLEAR_ALL: state => { Object.assign(state, getDefaultState()) },
  },
  actions: {
    clearTests: ({ commit }) => { commit('CLEAR_ALL') },
    getTests: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      commit('SET_TESTS_STATUS', apiStatus.LOADING)
      return axiosIns
        .get('/api/v2/tests', { params })
        .then(({ data }) => {
          commit('SET_TESTS', data)
          commit('SET_TESTS_STATUS', apiStatus.LOADED)
        })
        .catch(e => console.error(e))
    },
    fetchTests({ commit }, payload) {
      commit('SET_TESTS_STATUS', apiStatus.LOADING)
      commit('SET_TESTS', [])
      return new Promise((resolve, reject) => {
        axiosIns
          .post('/api/v2/tests', payload)
          .then(response => {
            commit('SET_TESTS', response.data.tests)
            commit('SET_TESTS_TOTAL', response.data.total_count)
            commit('SET_TESTS_STATUS', apiStatus.LOADED)
            resolve(response)
          })
          .catch(error => {
            console.error(error)
            reject(error)
          })
      })
    },
    getPageNumberByTestId({ commit }, payload) {
      const { modelId } = router.currentRoute.params
      const params = {
        modelId,
        perPage: payload.perPage,
      }
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/api/v2/tests/page_number_identifier/${payload.id}`, { params })
          .then(response => {
            commit('SET_PAGE_NUMBER', response.data)
            resolve(response)
          })
          .catch(error => {
            console.error(error)
            reject(error)
          })
      })
    },
  },
}
