<template>
  <b-modal
    id="view-issue-modal"
    :title="issue.display_id"
    size="lg"
    @close="closeModal"
    @ok="onSubmit"
  >
    <b-tabs v-if="!loading && issue.id">
      <b-tab title="Details" style="max-height: 75vh; overflow-y: auto">
        <IssueDetails :issue="issue" />
        <CommentCard class="mt-1" :parent-id="issue.id" parent-label="Issue" />
      </b-tab>
      <b-tab title="Context" style="max-height: 75vh; overflow-y: auto">
        <IssueContext :issue="issue" @route-change="closeModal" />
      </b-tab>
    </b-tabs>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="outline-secondary" @click="cancel()">
        Dismiss
      </b-button>

      <b-button variant="success" :disabled="loading" @click="ok()">

        <span v-if="loading">
          <b-spinner small type="grow" />
          Loading...
        </span>

        <span v-else>
          Update Issue
        </span>
      </b-button>
    </template>

  </b-modal>
</template>

<script>

import store from '@/store'
import { computed, ref } from '@vue/composition-api'
import IssueDetails from '@/components/Issues/IssueDetails.vue'
import IssueContext from '@/components/Issues/IssueContext.vue'
import CommentCard from '@/components/Comments/CommentCard.vue'

export default {
  components: {
    CommentCard,
    IssueDetails,
    IssueContext,
  },
  setup(props, context) {
    const loading = ref(false)
    const issue = computed(() => store.state.issues.selected_issue)

    const closeModal = () => {
      context.root.$bvModal.hide('view-issue-modal')
      store.dispatch('issues/setSelectedIssue', {})
      store.dispatch('issues/setUpdateFn', null)
      loading.value = false
    }

    const onSubmit = () => {
      loading.value = true

      const data = (({
        name, description, classification, severity, status,
      }) => ({
        name, description, classification, severity, status,
      }))(issue.value)

      store.dispatch('issues/updateIssue', { id: issue.value.id, data })
        .then(() => {
          if (store.state.issues.update_fn) store.state.issues.update_fn()
          store.commit('issues/SET_UPDATE_GRAPH', true)
        }).finally(() => closeModal())
    }

    return {
      loading,
      issue,

      closeModal,
      onSubmit,
    }
  },
}
</script>
