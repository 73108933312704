import beforeEnter from '@/router/modules/models/beforeEnter'

const routes = [
  {
    path: '/help-desk',
    name: 'help_desk',
    beforeEnter() { window.open('https://kompozition.atlassian.net/servicedesk/customer/portal/1') },
  },
]

export default routes
