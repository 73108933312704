export class ComponentApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get Component Image Ids
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentImageIds(id, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/component/{id}/get_image_ids',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Image
     * @param id
     * @param imageId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentImage(id, imageId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/component/{id}/get_image/{image_id}',
            path: {
                'id': id,
                'image_id': imageId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Upload Component Image
     * @param id
     * @param modelId
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    uploadComponentImage(id, modelId, formData) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/component/{id}/upload_image',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Set Component Current Image
     * @param id
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    setComponentCurrentImage(id, modelId, requestBody) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/component/{id}/current_image',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Component Image
     * @param id
     * @param imageId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteComponentImage(id, imageId, modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/component/{id}/delete_image/{image_id}',
            path: {
                'id': id,
                'image_id': imageId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Find Components
     * @param term
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    findComponents(term, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/component/find/{term}',
            path: {
                'term': term,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component
     * @param cptId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponent(cptId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/component/{cpt_id}',
            path: {
                'cpt_id': cptId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Context
     * @param cptId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentContext(cptId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/component/{cpt_id}/context',
            path: {
                'cpt_id': cptId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Children
     * @param cptId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentChildren(cptId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/component/{cpt_id}/children',
            path: {
                'cpt_id': cptId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Descendants
     * @param cptId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentDescendants(cptId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/component/{cpt_id}/descendant',
            path: {
                'cpt_id': cptId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Parent
     * @param cptId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentParent(cptId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/component/{cpt_id}/parent',
            path: {
                'cpt_id': cptId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Component Requirement Allocation
     * @param cptId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createComponentRequirementAllocation(cptId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/component/{cpt_id}/requirement_allocation',
            path: {
                'cpt_id': cptId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Component Requirement Allocation
     * @param cptId
     * @param reqId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteComponentRequirementAllocation(cptId, reqId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/component/{cpt_id}/requirement_allocation/{req_id}',
            path: {
                'cpt_id': cptId,
                'req_id': reqId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Component Allocation
     * @param cptId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createComponentAllocation(cptId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/component/{cpt_id}/component_allocation',
            path: {
                'cpt_id': cptId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Component Test Allocation
     * @param cptId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createComponentTestAllocation(cptId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/component/{cpt_id}/component_test_allocation',
            path: {
                'cpt_id': cptId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Component Allocation
     * @param cptId
     * @param otherId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteComponentAllocation(cptId, otherId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/component/{cpt_id}/component_allocation/{other_id}',
            path: {
                'cpt_id': cptId,
                'other_id': otherId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Component Build Configs
     * @param componentId
     * @param configIdList
     * @returns any Successful Response
     * @throws ApiError
     */
    getComponentBuildConfigs(componentId, configIdList) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/component/{component_id}/build_configs',
            path: {
                'component_id': componentId,
            },
            query: {
                'config_id_list': configIdList,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
