import beforeEnter from '@/router/modules/models/beforeEnter'

const routes = [
  {
    path: '/model/:modelId/test/:testId',
    name: 'model_test',
    component: () => import('@/views/Test/TestCase.vue'),
    beforeEnter,
    meta: {
      auth: true,
      contentRenderer: 'default',
      pageTitle: 'Test Case',
      breadcrumb: [
        {
          text: 'Tests',
          to: {
            name: 'model_tests',
          },
        },
        {
          text: 'Test',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/tests?focus=:testId',
    name: 'model_test_focus',
    component: () => import('@/views/Tests/TestCases.vue'),
    beforeEnter,
    meta: {
      auth: true,
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      pageTitle: 'Tests',
      titleHidden: true,
      breadcrumb: [
        {
          text: 'Tests',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/tests',
    name: 'model_tests',
    component: () => import('@/views/Tests/TestCases.vue'),
    beforeEnter,
    meta: {
      auth: true,
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      pageTitle: 'Tests',
      titleHidden: true,
      breadcrumb: [
        {
          text: 'Tests',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/tests?watch=:watch',
    name: 'model_tests_watch',
    component: () => import('@/views/Tests/TestCases.vue'),
    meta: {
      auth: true,
      navActiveLink: 'model_tests',
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      pageTitle: 'Tests',
      titleHidden: true,
      breadcrumb: [
        {
          text: 'Tests',
          active: true,
        },
      ],
    },
    beforeEnter(to, _, next) {
      if (['true', 'false'].includes(to.params.watch)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/model/:modelId/tests?status=:filter',
    name: 'model_tests_filter',
    component: () => import('@/views/Tests/TestCases.vue'),
    meta: {
      auth: true,
      navActiveLink: 'model_tests',
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      pageTitle: 'Tests',
      titleHidden: true,
      breadcrumb: [
        {
          text: 'Tests',
          active: true,
        },
      ],
    },
    beforeEnter(to, _, next) {
      if (['passed', 'failed', 'untested'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/model/:modelId/tests?priority=:priority',
    name: 'model_tests_priority',
    component: () => import('@/views/Tests/TestCases.vue'),
    meta: {
      auth: true,
      navActiveLink: 'model_tests',
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      pageTitle: 'Tests',
      titleHidden: true,
      breadcrumb: [
        {
          text: 'Tests',
          active: true,
        },
      ],
    },
    beforeEnter(to, _, next) {
      if (['low', 'nominal', 'high', 'critical'].includes(to.params.priority)) next()
      else next({ name: 'error-404' })
    },
  },
]

export default routes
