<template>
  <div class="d-flex align-items-start">
    <b-avatar
      size="34"
      class="mt-25 mr-25"
    >
      {{ getUserUsername(comment.created_by).charAt(0).toUpperCase() || '?' }}
    </b-avatar>
    <div class="profile-user-info w-100 border-rounded-md p-50">
      <div class="d-flex align-items-center justify-content-between">
        <span class="mb-0 w-100 font-weight-bolder font-small-3">
          {{ getUserUsername(comment.created_by) || 'Anonymous User' }}
          <span
            id="timestamp"
            v-b-tooltip.hover.bottom="createdDate(comment.created)"
            class="font-small-1 text-muted float-right"
          >
            {{ createdDateShort(comment.created) }}
          </span>
        </span>
        <span>
          <b-dropdown
            v-if="auth.email === getUserUsername(comment.created_by)"
            right
            variant="link"
            no-caret
            toggle-class="p-0"
          >
            <template #button-content>
              <feather-icon
                icon="MoreHorizontalIcon"
                size="18"
                class="hide text-body cursor-pointer"
              />
            </template>
            <b-dropdown-item @click="deleteComment">
              <span class="text-danger">Delete comment</span>
            </b-dropdown-item>
          </b-dropdown>
        </span>
      </div>

      <p class="font-small-3 px-25 mb-50">
        {{ comment.message }}
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'CommentBox',
  props: {
    comment: {
      type: Object,
      default: null,
    },
  },
  computed: {
    auth() {
      return this.$store.state.auth
    },
  },
  methods: {
    deleteComment() {
      this.$store.dispatch('comments/selectComment', this.comment)
        .then(() => { this.$bvModal.show('delete-comment-modal') })
    },
    formatToLocalTime(date) {
      const testDateUtc = moment.utc(date)
      return moment(testDateUtc).local()
    },
    createdDateShort(date) {
      const localDate = this.formatToLocalTime(date)
      return moment(localDate).fromNow()
    },
    createdDate(date) {
      const localDate = this.formatToLocalTime(date)
      return localDate.format('LLLL')
    },
  },
}
</script>

<style scoped>
#timestamp {
   font-size: 0.5rem;
   float: right;
   padding:0.2rem 0.6rem 0 0;
}

.hide {
    max-height: 1rem;
    display: none;
}

.profile-user-info {
   display: block !important;
}

.profile-user-info:hover .hide{
  display: inline-block;
}
</style>
