import { apiStatus } from '@/enums'
import coreService from '@/libs/api-services/core-service'
import axiosIns from '@/libs/axios'
import router from '@/router'

const COLUMNS_REQUIREMENTS_KEY = 'columns_requirements'
/// Get the visible requirements table columns from the nearest storage
// vuex -> session -> localstorage
// Creates the session storage entry if a localstorage entry is found
function getSavedColumns() {
  const savedSessionColumns = window.sessionStorage.getItem(COLUMNS_REQUIREMENTS_KEY)
  let savedLocalStorageColumns = ''
  if (!savedSessionColumns) {
    savedLocalStorageColumns = window.localStorage.getItem(COLUMNS_REQUIREMENTS_KEY)
    if (savedLocalStorageColumns) {
      window.sessionStorage.setItem('columns', savedLocalStorageColumns)
    }
  }
  return savedSessionColumns || savedLocalStorageColumns
}

const getDefaultState = () => {
  const defaultState = {
    data: [],
    data_status: apiStatus.INIT,
    layout: [],
    layout_status: apiStatus.INIT,
    columns: ['section', 'display_id', 'object_text', 'priority'],
    mode: 'document',
    specification: null,
    snapshot: {
      snapshot_name: '',
      specification_title: '',
    },
    show_deleted: false,
  }
  const savedColumns = getSavedColumns()
  if (savedColumns) {
    defaultState.columns = JSON.parse(savedColumns)
  }
  return defaultState
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    modeLabel: state => (state.mode === 'document' ? 'Document mode' : 'Backlog mode'),
  },
  mutations: {
    SET_DATA: (state, data) => { state.data = data },
    SET_DATA_STATUS: (state, status) => { state.data_status = status },
    SET_LAYOUT: (state, layout) => { state.layout = layout },
    SET_LAYOUT_STATUS: (state, status) => { state.layout_status = status },
    SET_COLUMNS: (state, columns) => { state.columns = columns },
    SET_MODE: (state, mode) => { state.mode = mode },
    SET_SNAPSHOT: (state, snapshot) => { state.snapshot = snapshot },
    SET_SPECIFICATION: (state, specification) => { state.specification = specification },
    SET_SHOW_DELETED: (state, deleted) => { state.show_deleted = deleted },
    CLEAR_ALL: state => { Object.assign(state, getDefaultState()) },
  },
  actions: {
    clearRequirementsTable: ({ commit }) => { commit('CLEAR_ALL') },
    getData: ({ commit, state, dispatch }, payload) => {
      const { modelId } = router.currentRoute.params
      const { columns, requirements, setLayout } = payload
      if (!(requirements && requirements.length === 1)) {
        commit('SET_DATA_STATUS', apiStatus.LOADING)
      }
      const { specification } = state
      const config = { headers: { 'Model-Id': modelId } }
      const columnData = { columns: columns || state.columns }
      return coreService
        .post(`/v1/specifications/${specification}/requirements`, columnData, config)
        .then(({ data }) => {
          // "data" is a map (id as the key)
          // "requirementsData" is an array
          // This action is used in 2 ways:
          // 1. To initially get all the requirement data for the store
          // 2. To get any extra columns of data and append them to each requirement
          const requirementsData = state.data
          Object.keys(data)
            .forEach(id => {
              const index = requirementsData.findIndex(req => req.id === id)
              if (index >= 0) {
                // replacement is not reactive, splice is
                requirementsData.splice(index, 1, { ...requirementsData[index], ...data[id] })
              } else {
                requirementsData.push(data[id])
              }
            })
          commit('SET_DATA', requirementsData)
          commit('SET_DATA_STATUS', apiStatus.LOADED)
          if (setLayout) {
            return dispatch('getLayout', !(requirements && requirements.length === 1))
          }
          return null
        })
    },
    getLayout({ commit, state }, showLoading = true) {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      if (showLoading) {
        commit('SET_LAYOUT_STATUS', apiStatus.LOADING)
      }
      const { specification, mode } = state
      return axiosIns
        .post('/api/v2/requirements/table_layout', { specification, mode }, { params })
        .then(({ data }) => {
          commit('SET_LAYOUT', data)
          commit('SET_LAYOUT_STATUS', apiStatus.LOADED)
        })
    },
    setColumns({ commit, state, dispatch }, columns) {
      if (state.mode === 'document') {
        window.localStorage.setItem(COLUMNS_REQUIREMENTS_KEY, JSON.stringify(columns))
        window.sessionStorage.setItem(COLUMNS_REQUIREMENTS_KEY, JSON.stringify(columns))
      }
      const addedColumns = columns.filter(col => !state.columns.includes(col))
      commit('SET_COLUMNS', columns)
      if (addedColumns.length) {
        dispatch('getData', { columns: addedColumns })
      }
    },
    setMode({ commit, dispatch }, { mode, snapshotId }) {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      commit('SET_MODE', mode)
      if (mode === 'snapshot_compare') {
        axiosIns
          .get(`/api/v2/specifications/snapshot_compare/${snapshotId}`, { params })
          .then(({ data }) => {
            commit('SET_SPECIFICATION', data.specification_id)
            commit('SET_SHOW_DELETED', true)
            commit('SET_SNAPSHOT', data)
            dispatch('getLayout')
          })
      } else {
        dispatch('getLayout')
      }
      if (mode === 'document') {
        const columns = JSON.parse(getSavedColumns() || '[]')
        dispatch('setColumns', columns)
      } else {
        dispatch('setColumns', [mode === 'backlog' ? 'sequence' : 'section', 'display_id', 'object_text', 'priority'])
      }
    },
    setSpecification({ commit, dispatch }, specification) {
      commit('SET_SPECIFICATION', specification)
      commit('SET_DATA', [])
      dispatch('getData', {})
      dispatch('getLayout')
    },
  },
}
