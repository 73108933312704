export class GroupManagementApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get All Groups
     * Get all groups of the Keycloak realm (Kompozition)
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllGroups() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/groups',
        });
    }
    /**
     * Create Group
     * Create a group on the Kompozition realm
     * @param groupName
     * @param parentId
     * @returns any Successful Response
     * @throws ApiError
     */
    createGroup(groupName, parentId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/groups',
            query: {
                'group_name': groupName,
                'parent_id': parentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Group
     * Deletes a group on the Kompozition realm
     * @param groupId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteGroup(groupId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/auth/groups',
            query: {
                'group_id': groupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Group
     * Returns full entry of Group based on group name
     * @param groupName
     * @returns any Successful Response
     * @throws ApiError
     */
    getGroup(groupName) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/groups/{group_name}',
            path: {
                'group_name': groupName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Group
     * @param groupId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateGroup(groupId, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/auth/groups/{group_id}',
            path: {
                'group_id': groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Group By Path
     * Return Group based on path
     * @param path
     * @returns any Successful Response
     * @throws ApiError
     */
    getGroupByPath(path) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/groups/group-by-path/{path}',
            path: {
                'path': path,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Group Members
     * @param groupId
     * @returns any Successful Response
     * @throws ApiError
     */
    getGroupMembers(groupId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/groups/{group_id}/members',
            path: {
                'group_id': groupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Group Roles
     * @param groupId
     * @returns any Successful Response
     * @throws ApiError
     */
    getGroupRoles(groupId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/groups/{group_id}/roles',
            path: {
                'group_id': groupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Group Roles
     * @param groupId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    addGroupRoles(groupId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/groups/{group_id}/roles',
            path: {
                'group_id': groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove Group Roles
     * @param groupId
     * @param roles
     * @returns any Successful Response
     * @throws ApiError
     */
    removeGroupRoles(groupId, roles) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/auth/groups/{group_id}/roles',
            path: {
                'group_id': groupId,
            },
            query: {
                'roles': roles,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
