export class ModelApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Validate Model Details
     * Check if a model's name or key already exist in a database
 * return:
 * dictionary in the format of
 * { errors: { modelName: [], ModelKey: [] } }
     * @param modelName
     * @param modelKey
     * @returns any Successful Response
     * @throws ApiError
     */
    validateModelDetails(modelName, modelKey) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/validate',
            query: {
                'model_name': modelName,
                'model_key': modelKey,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Validate Model Name
     * Check if a model's name exist in a database and if its blank
 * return:
 * dictionary in the format of
 * { errors: { modelName: [] } }
     * @param modelName
     * @returns any Successful Response
     * @throws ApiError
     */
    validateModelName(modelName) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/validate_model_name',
            query: {
                'model_name': modelName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Snapshot
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getSnapshot(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/{model_id}/snapshot',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Model Snapshot
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    createModelSnapshot(modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/model/{model_id}/snapshot',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Snapshot
     * @param modelId
     * @param snapshotId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteSnapshot(modelId, snapshotId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/model/{model_id}/snapshot/{snapshot_id}',
            path: {
                'model_id': modelId,
                'snapshot_id': snapshotId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Convert Snapshot
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    convertSnapshot(modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/model/{model_id}/snapshot/convert',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Temp Model
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteTempModel(modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/model/{model_id}/temp_model',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Import Snapshot
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    importSnapshot(formData) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/model/snapshot/import',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Download Snapshot
     * @param modelId
     * @param snapshotId
     * @returns any Successful Response
     * @throws ApiError
     */
    downloadSnapshot(modelId, snapshotId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/{model_id}/snapshot/{snapshot_id}/download',
            path: {
                'model_id': modelId,
                'snapshot_id': snapshotId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Model
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getModel(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/{model_id}',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Model
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteModel(modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/model/{model_id}',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Temp Models
     * @returns any Successful Response
     * @throws ApiError
     */
    getTempModels() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/temp',
        });
    }
    /**
     * Get All Models
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllModels() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/all',
        });
    }
    /**
     * Get Models Shared
     * @returns any Successful Response
     * @throws ApiError
     */
    getModelsShared() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/shared',
        });
    }
    /**
     * Get Models Public
     * @returns any Successful Response
     * @throws ApiError
     */
    getModelsPublic() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/public',
        });
    }
    /**
     * Get Models By User
     * @returns any Successful Response
     * @throws ApiError
     */
    getModelsByUser() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/user',
        });
    }
    /**
     * Get Model Properties
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getModelProperties(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/{model_id}/properties',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Users Shared With
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getUsersSharedWith(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/{model_id}/shared_with',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Share Model With User
     * @param modelId
     * @param username
     * @returns any Successful Response
     * @throws ApiError
     */
    shareModelWithUser(modelId, username) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/model/{model_id}/shared_with/{username}',
            path: {
                'model_id': modelId,
                'username': username,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Unshare Model From User
     * @param modelId
     * @param username
     * @returns any Successful Response
     * @throws ApiError
     */
    unshareModelFromUser(modelId, username) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/model/{model_id}/shared_with/{username}',
            path: {
                'model_id': modelId,
                'username': username,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get All Components
     * @param modelId
     * @param subtype
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllComponents(modelId, subtype) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/{model_id}/components',
            path: {
                'model_id': modelId,
            },
            query: {
                'subtype': subtype,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Linked Models
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getLinkedModels(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/{model_id}/links',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Query Model Link
     * @param modelId
     * @param model2Id
     * @returns any Successful Response
     * @throws ApiError
     */
    queryModelLink(modelId, model2Id) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/{model_id}/link/{model2_id}',
            path: {
                'model_id': modelId,
                'model2_id': model2Id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Model Link
     * @param modelId
     * @param model2Id
     * @returns any Successful Response
     * @throws ApiError
     */
    createModelLink(modelId, model2Id) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/model/{model_id}/link/{model2_id}',
            path: {
                'model_id': modelId,
                'model2_id': model2Id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Model Link
     * @param modelId
     * @param model2Id
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteModelLink(modelId, model2Id) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/model/{model_id}/link/{model2_id}',
            path: {
                'model_id': modelId,
                'model2_id': model2Id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Change Model Ownership
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    changeModelOwnership(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/model/{model_id}/owner',
            path: {
                'model_id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
