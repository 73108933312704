const routes = [
  {
    path: '/model/:modelId/trade-studies',
    name: 'trade_study',
    component: () => import('@/views/TradeStudy/TradeStudies.vue'),
    meta: {
      auth: true,
      pageTitle: 'Trade Studies',
      breadcrumb: [
        {
          text: 'Trade Studies',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/trade-studies/:tradeId',
    name: 'trade_study_dedicated',
    component: () => import('@/views/TradeStudy/components/dedicatedPageCards/TradeStudySidecard.vue'),
    meta: {
      auth: true,
      pageTitle: 'Trade Study',
      dynamicTitle: true,
      breadcrumb: [
        {
          text: 'Trade Studies',
          to: {
            name: 'trade_study',
          },
        },
        {
          text: 'Trade Study',
          active: true,
        },
      ],
    },
  },
]

export default routes
