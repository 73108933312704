import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    dynamicPageTitle: '',
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, newValue) {
      state.windowWidth = newValue
    },
    TOGGLE_OVERLAY(state, newValue) {
      state.shallShowOverlay = newValue !== undefined ? newValue : !state.shallShowOverlay
    },
    SET_DYNAMIC_PAGE_TITLE(state, newValue) {
      state.dynamicPageTitle = newValue
    },
    CLEAR_DYNAMIC_PAGE_TITLE(state) {
      state.dynamicPageTitle = ''
    },
  },
  actions: {},
}
