import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
import Toast, { POSITION } from 'vue-toastification'

// Toast Notification Component Styles
import '@core/scss/vue/libs/toastification.scss'

/**
 * NOTE: If you are using other transition them make sure to import it in `~@core/scss/vue/libs/notification.scss` from it's source
 */
Vue.use(Toast, {
  icon: false,
  position: POSITION.BOTTOM_RIGHT,
  timeout: 6000,
  pauseOnHover: true,
  closeOnClick: true,
  closeButton: false,
  transition: 'Vue-Toastification__bounce',
})
