import Vue from 'vue'
import coreService from '@/libs/api-services/core-service'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const getDefaultState = () => ({
  targetId: null,
  targetRelation: null,
})

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    SET_TARGET: (state, data) => {
      state.targetId = data.targetId
      state.targetRelation = data.targetRelation
    },
    CLEAR_TARGET: state => {
      state.targetId = null
      state.targetRelation = null
    },
    CLEAR_ALL: state => { Object.assign(state, getDefaultState()) },
  },
  actions: {
    /**
     *
     * REQUIREMENT CRUD
     *
     */
    createRequirement({ commit, state }, data) {
      const { modelId, specId } = router.currentRoute.params
      const requirementData = {
        priority: data.priority,
        classification: data.classification,
        object_text: data.object_text,
        additional_attributes: data.additional_attributes,
        target: {
          // If a target requirement isn't set, use the parent specification and
          //  make the requirement a child of it
          target_id: state.targetId ? state.targetId : specId,
          target_relation: state.targetRelation ? state.targetRelation : 'child',
        },
      }

      return coreService.requirementsApi
        .createRequirement(specId, modelId, requirementData)
        .then(response => {
          const displayId = response.display_id
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Requirement created',
              text: `${displayId}`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => { console.error(`Error creating requirement: ${e}`) })
        .finally(() => { commit('CLEAR_TARGET') })
    },
    getRequirementDetails: ({ rootState, commit }, reqId) => {
      const { modelId } = router.currentRoute.params
      const headers = { 'Model-Id': modelId }
      return coreService
        .get(`v1/requirement/${reqId}`, { headers })
        .catch(error => console.error(error))
    },
    updateRequirementDetails: ({ rootState, commit }, payload) => {
      const { id, etag, data } = payload
      const { modelId } = router.currentRoute.params

      const headers = { 'Model-Id': modelId, 'If-Match': etag }
      return coreService
        .patch(`v1/requirement/${id}`, data, { headers })
        .then(response => {
          const displayId = response.data.display_id
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Requirement updated',
              text: `${displayId}`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          return response
        })
        .catch(error => console.error(error))
    },
    deleteRequirement({ state }, reqId) {
      const { modelId } = router.currentRoute.params
      return coreService.requirementsApi
        .deleteRequirement(reqId, modelId)
        .then(response => {
          const count = response.deletedRequirements.length
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: `Deleted ${count === 1 ? '1 Requirement' : `${count} Requirements`}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
        .catch(e => { console.error(`Error creating requirement: ${e}`) })
    },

    /**
     *
     * REQUIREMENT LINKS
     *
     */
    getRequirementAllocations: ({ rootState, commit }, reqId) => {
      const { modelId } = router.currentRoute.params
      return coreService.requirementsApi
        .getRequirementAllocations(reqId, modelId)
        .catch(error => console.error(error))
    },
    getRequirementLinks: ({ rootState, commit }, reqId) => {
      const { modelId } = router.currentRoute.params
      return coreService.requirementsApi
        .getRequirementLinks(reqId, modelId)
        .catch(error => console.error(error))
    },
    getRequirementTrace: ({ rootState, commit }, reqId) => {
      const { modelId } = router.currentRoute.params
      return coreService.requirementsApi
        .getRequirementTrace(reqId, modelId)
        .catch(error => console.error(error))
    },
    getRequirementCoverage: ({ rootState, commit }, reqId) => {
      const { modelId } = router.currentRoute.params
      return coreService.requirementsApi
        .getRequirementCoverage(reqId, modelId)
        .catch(error => console.error(error))
    },
    clearRequirementStore: ({ commit }) => { commit('CLEAR_ALL') },
  },
}
