import AdminRoutes from '@/router/modules/settings/admin'

const routes = [
  {
    path: '/application-details',
    name: 'application_details',
    component: () => import('@/views/Settings/AboutPage.vue'),
    meta: {
      pageTitle: 'Application Details',
      auth: true,
      breadcrumb: [
        {
          text: 'Application Details',
          active: true,
        },
      ],
    },
  },
  ...AdminRoutes,
  // {
  //   path: '/settings/general',
  //   name: 'settings_general',
  //   component: () => import('@/views/Settings/General.vue'),
  //   meta: {
  //     pageTitle: 'General Settings',
  //     auth: true,
  //     breadcrumb: [
  //       {
  //         text: 'General Settings',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/settings/application',
  //   name: 'settings_application',
  //   component: () => import('@/views/Settings/Application.vue'),
  //   meta: {
  //     pageTitle: 'Application Settings',
  //     auth: true,
  //     breadcrumb: [
  //       {
  //         text: 'Application Settings',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]

export default routes
