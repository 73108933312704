export class HistoryApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Add History
     * Add to the nodes historic data
 * You call this just before you update the node, so that the previous version is saved.
 * Returns the Destination Node (The new Node created)
     * @param parentNodeId
     * @returns any Successful Response
     * @throws ApiError
     */
    addHistory(parentNodeId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/node_history/add_history/{parent_node_id}',
            path: {
                'parent_node_id': parentNodeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete All History
     * Delete all the history associated to the Node.
 * This endpoint is to be used if the parent node is to be permanently deleted
     * @param parentNodeId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteAllHistory(parentNodeId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/node_history/delete_all_history/{parent_node_id}',
            path: {
                'parent_node_id': parentNodeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Individual History
     * Delete the history node and relink the one before if exists
 * This endpoint is to be used if an update fails after the history node is already created
     * @param historyNodeId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteIndividualHistory(historyNodeId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/node_history/delete_individual_history/{history_node_id}',
            path: {
                'history_node_id': historyNodeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get History
     * Return all the history for the Node, in order (top of the list is latest, bottom is oldest)
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    getHistory(id) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/node_history/get_history/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Relationship History
     * Add a Relationship History record for the nodes provided
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    addRelationshipHistory(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/node_history/add_relationship_history',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get History Node Types
     * Return all the Node Types that have a History Node attached to them within the model
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getHistoryNodeTypes(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/node_history/get_history_node_types/{model_id}',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
