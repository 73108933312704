export class DefaultApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Api Health Check
     * @returns any Successful Response
     * @throws ApiError
     */
    apiHealthCheckHealthcheckGet() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/healthcheck',
        });
    }
}
