<template>
  <b-modal
    id="update-note-modal"
    size="lg"
    title="Update note"
    ok-title="Update note"
    ok-variant="success"
    cancel-title="Discard"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @show="onShow"
    @hide="onHide"
    @ok="onSubmit"
  >
    <!-- Note type -->
    <b-form-group label="Note Type" label-for="ddlNoteType">
      <b-form-select id="ddlNoteType" v-model="localNote.type" :options="noteTypes" />
    </b-form-group>

    <!-- Note content -->
    <b-form-group
      label="Note Details"
      label-for="txtQuillNote"
      class="mt-2"
    >
      <tip-tap-editor
        id="txtQuillNote"
        v-model="localNote.text"
        placeholder="Compose a note..."
        min-height="16"
        max-height="50"
        :allow-image-upload="false"
      />
    </b-form-group>
  </b-modal>
</template>

<script>
import store from '@/store'
import { computed, ref } from '@vue/composition-api'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'

export default {
  name: 'UpdateNoteModal',
  components: { TipTapEditor },
  setup(props, context) {
    let etag
    const noteId = computed(() => store.state.notes.modal_target_note_id)

    const localNote = ref({ type: 'note', text: '' })
    const noteTypes = [
      { text: 'Regular note', value: 'note' },
      { text: 'How might we...?', value: 'HMW' },
    ]

    const fetchNote = () => {
      localNote.value = { type: 'note', text: '' }
      store.dispatch('notes/fetchNote', noteId)
        .then(response => {
          if (response === null) { closeModal() }
          etag = response.etag
          localNote.value = response.data
        })
    }
    const closeModal = () => {
      etag = undefined
      localNote.value = { type: 'note', text: '' }
      store.dispatch('notes/setGlobalNoteId', null)
      context.root.$bvModal.hide('update-note-modal')
    }

    const onShow = () => { fetchNote() }
    const onHide = () => { closeModal() }
    const onSubmit = () => {
      const data = { type: localNote.value.type, text: localNote.value.text }
      store.dispatch('notes/updateNote', {
        noteId: noteId.value, etag, data,
      }).finally(() => { closeModal() })
    }

    return {
      localNote,
      noteTypes,

      onShow,
      onHide,
      onSubmit,
    }
  },
}
</script>
