import Vue, { PluginObject } from 'vue'
import Keycloak, { KeycloakConfig } from 'keycloak-js'

const _keycloak = new Keycloak({
  url: `${window.location.protocol}//auth.${window.location.host}`,
  realm: process.env.VUE_APP_AUTH_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_AUTH_KEYCLOAK_CLIENT_ID,
})

const Plugin = {
  install: Vue => {
    Vue.$keycloak = _keycloak
  },
}

Plugin.install = Vue => {
  Vue.$keycloak = _keycloak
  Object.defineProperties(Vue.prototype, {
    $keycloak: {
      get() {
        return _keycloak
      },
    },
  })
}

Vue.prototype.$user = () => {
  Vue.$keycloak.loadUserProfile()
    .then(data => {
      console.log('Loading user profile...')
      return data
    }).catch(() => {
      console.error('Failed to load user profile')
      // TODO: Toast Alert('Failed to load user profile')
    })
}

/**
 * Check if the currently logged in User is a member
 * of any of the listed roles in the 'allowedRoles' array.
 * @param allowedRoles : Array of allow roles to view component
 * @returns {boolean}
 */
Vue.prototype.$userRoleAccess = allowedRoles => {
  const currentUserRoles = Vue.$keycloak.realmAccess.roles
  return allowedRoles.some(r => currentUserRoles.indexOf(r) >= 0)
}

Vue.use(Plugin)

export default Plugin
