import coreService from '@/libs/api-services/core-service'

const getDefaultState = () => ({
  // Generic stuff
  selectedParentNode: '',
  selectedNodeHistory: '',
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    SET_SELECTED_HISTORY_PARENT_NODE: (state, data) => { state.selectedParentNode = data },
    SET_SELECTED_HISTORY: (state, data) => { state.selectedNodeHistory = data },
  },
  actions: {
    getNodeHistory: ({ commit, rootState }, historyParentId) => coreService
      .get(`/v1/node_history/get_history/${historyParentId}`)
      .then(({ data }) => {
        commit('SET_SELECTED_HISTORY', data.history)
        commit('SET_SELECTED_HISTORY_PARENT_NODE', data.parent)
      })
      .catch(e => console.error(e)),
  },
}
