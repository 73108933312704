<template>
  <div>
    <b-button
      v-b-tooltip.hover.left="`Update linked ${label}`"
      size="sm"
      class="mb-25"
      :variant="modal ? 'flat-success' : 'flat-secondary'"
      :disabled="!modal"
      @click="openModal"
    >
      <feather-icon :icon="modal ? 'LinkIcon' : 'MinusIcon'" />
    </b-button>

    <span class="font-medium-2">Linked {{ label }}</span>

    <b-list-group
      v-if="entityArray.length > 0"
    >
      <ListGroupItemInterface
        v-for="(item,index) in entityArray"
        :key="index"
        :an-interface="item"
      />
    </b-list-group>

    <div v-else>
      <span class="ml-3 font-small-3 text-muted">No linked {{ label }}</span>
    </div>
  </div>
</template>

<script>
import { BButton, BListGroup, VBTooltip } from 'bootstrap-vue'
import ListGroupItemInterface from '@/components/Forms/ListGroupItems/ListGroupItemInterface.vue'

export default {
  name: 'ListGroupInterface',
  components: {
    BButton,
    BListGroup,
    ListGroupItemInterface,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    entityArray: {
      type: Array,
      required: true,
    },
    modal: {
      type: String,
      required: true,
    },
  },
  methods: {
    openModal() {
      this.$bvModal.show(this.modal)
    },
  },
}
</script>
