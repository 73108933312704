export class UserManagementApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get User
     * Get a User by its id value
     * @param userId
     * @returns any Successful Response
     * @throws ApiError
     */
    getUser(userId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/user/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update User
     * Update the details for a specific user
     * @param userId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateUser(userId, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/auth/user/{user_id}',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete User
     * Delete a user
     * @param userId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteUser(userId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/auth/user/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get User By Query
     * Queries the keycloak API for a specific user either based on its ID or any **native** attribute
     * @param query
     * @returns any Successful Response
     * @throws ApiError
     */
    getUserByQuery(query) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/user',
            query: {
                'query': query,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update User Attributes
     * Update the attributes for a specific user
     * @param userId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateUserAttributes(userId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/user/{user_id}/attributes',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Activate User
     * Activate a user
     * @param userId
     * @returns any Successful Response
     * @throws ApiError
     */
    activateUser(userId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/user/{user_id}/activate',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Deactivate User
     * Deactivate a user
     * @param userId
     * @returns any Successful Response
     * @throws ApiError
     */
    deactivateUser(userId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/user/{user_id}/deactivate',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Send Email Verification
     * Send email verification to the users email address
     * @param userId
     * @returns any Successful Response
     * @throws ApiError
     */
    sendEmailVerification(userId) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/auth/user/{user_id}/send-email-verification',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Change Password
     * Change the user password. Only the user or an administrator can change the password.
     * @param userId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    changePassword(userId, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/auth/user/{user_id}/change-password',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Set Temporary Password
     * @param userId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    setTemporaryPassword(userId, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/auth/user/{user_id}/set-temporary-password',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Send Password Reset Email
     * @param userId
     * @returns void
     * @throws ApiError
     */
    sendPasswordResetEmail(userId) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/auth/user/{user_id}/reset-password',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get User Avatar
     * Get the avatar image for a specific user
     * @param userId
     * @returns binary Successful Response
     * @throws ApiError
     */
    getUserAvatar(userId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/user/{user_id}/avatar',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Upload User Avatar
     * Upload an avatar image for a specific user
     * @param userId
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    uploadUserAvatar(userId, formData) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/user/{user_id}/avatar',
            path: {
                'user_id': userId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete User Avatar
     * @param userId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteUserAvatar(userId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/auth/user/{user_id}/avatar',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get User Roles
     * Get all roles of a specific user
     * @param userId
     * @returns any Successful Response
     * @throws ApiError
     */
    getUserRoles(userId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/user/{user_id}/roles',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add User Roles
     * Adds roles to a specific user
     * @param userId
     * @param roles
     * @returns any Successful Response
     * @throws ApiError
     */
    addUserRoles(userId, roles) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/user/{user_id}/roles',
            path: {
                'user_id': userId,
            },
            query: {
                'roles': roles,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove User Roles
     * Removes roles (list of role names) from a specific user
     * @param userId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    removeUserRoles(userId, requestBody) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/auth/user/{user_id}/roles',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get User Groups
     * Gets all groups of a specific user
     * @param userId
     * @returns any Successful Response
     * @throws ApiError
     */
    getUserGroups(userId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/user/{user_id}/groups',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Groups To User
     * Add groups to a specific user
     * @param userId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    addGroupsToUser(userId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/user/{user_id}/groups',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove User Group
     * Remove group from a specific user
     * @param userId
     * @param groupId
     * @returns any Successful Response
     * @throws ApiError
     */
    removeUserGroup(userId, groupId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/auth/user/{user_id}/groups',
            path: {
                'user_id': userId,
            },
            query: {
                'group_id': groupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get User Events
     * @param userId
     * @returns any Successful Response
     * @throws ApiError
     */
    getUserEvents(userId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/user/{user_id}/events',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get User Sessions
     * @param userId
     * @returns any Successful Response
     * @throws ApiError
     */
    getUserSessions(userId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/user/{user_id}/sessions',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
