export class RequirementsApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get Requirement
     * Get a Requirement with the given unique {id} that belongs to {model_id}
 * :param id: UUID of the Requirement node
 * :param request: HTTP Request handler, used to include User information
 * :param response: HTTP Response handler, used to include ETag header
 * :param model_id: UUID of the Model node
 * :return: The found Requirement node or a 404 HTTPException if not found
 * :rtype: Requirement
     * @param id
     * @param modelId
     * @returns Requirement Successful Response
     * @throws ApiError
     */
    getRequirement(id, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/requirement/{id}',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Requirement
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteRequirement(id, modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/requirement/{id}',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirement Head
     * Get the ETag for a Requirement with the given unique {id} that belongs to {model_id}
 * :param id: UUID of the Requirement node
 * :param request: HTTP Request handler, used to include User information
 * :param response: HTTP Response handler, used to include ETag header
 * :param model_id: UUID of the Model node
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirementHead(id, modelId) {
        return this.httpRequest.request({
            method: 'HEAD',
            url: '/v1/requirement/{id}',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Requirement
     * @param id
     * @param modelId
     * @param ifMatch
     * @param requestBody
     * @returns Requirement Successful Response
     * @throws ApiError
     */
    updateRequirement(id, modelId, ifMatch, requestBody) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/requirement/{id}',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
                'if-match': ifMatch,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Requirement
     * @param specId
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createRequirement(specId, modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/requirement',
            headers: {
                'spec-id': specId,
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Delete Requirements
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    bulkDeleteRequirements(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/requirement/delete',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Bulk Requirements
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateBulkRequirements(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/requirement/update_bulk',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Restore Requirement
     * @param id
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    restoreRequirement(id, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/requirement/{id}/restore',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Copy Requirement
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    copyRequirement(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/requirement/copy',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Move Requirement
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    moveRequirement(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/requirement/move',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Reorder Requirement
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    reorderRequirement(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/requirement/reorder',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Add Requirements
     * @param specId
     * @param modelId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    bulkAddRequirements(specId, modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/requirement/{spec_id}/bulk_add_csv',
            path: {
                'spec_id': specId,
            },
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirement Trace
     * @param reqId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirementTrace(reqId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/requirement/{req_id}/trace',
            path: {
                'req_id': reqId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Or Update Trace Links
     * @param reqId
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createOrUpdateTraceLinks(reqId, modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/requirement/{req_id}/trace',
            path: {
                'req_id': reqId,
            },
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirement Coverage
     * @param reqId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirementCoverage(reqId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/requirement/{req_id}/coverage',
            path: {
                'req_id': reqId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Or Update Coverage Links
     * @param reqId
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createOrUpdateCoverageLinks(reqId, modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/requirement/{req_id}/coverage',
            path: {
                'req_id': reqId,
            },
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Read Trace Link
     * @param traceId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    readTraceLink(traceId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/requirement/trace/{trace_id}',
            path: {
                'trace_id': traceId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Trace Link
     * @param traceId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteTraceLink(traceId, modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/requirement/trace/{trace_id}',
            path: {
                'trace_id': traceId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Trace Link
     * @param traceId
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    updateTraceLink(traceId, modelId, requestBody) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/requirement/trace/{trace_id}',
            path: {
                'trace_id': traceId,
            },
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirement Allocations
     * @param reqId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirementAllocations(reqId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/requirement/{req_id}/allocations',
            path: {
                'req_id': reqId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Requirement Links
     * @param reqId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getRequirementLinks(reqId, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/requirement/{req_id}/links',
            path: {
                'req_id': reqId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Mutual Issues For Requirements
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    getMutualIssuesForRequirements(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bulk_requirements/issues',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Issue Links
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createIssueLinks(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bulk_requirements/link/issues',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove Issue Links
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    removeIssueLinks(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bulk_requirements/unlink/issues',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Mutual Tests For Requirements
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    getMutualTestsForRequirements(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bulk_requirements/tests',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Test Links
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createTestLinks(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bulk_requirements/link/tests',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove Test Links
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    removeTestLinks(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bulk_requirements/unlink/tests',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Mutual Releases For Requirements
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    getMutualReleasesForRequirements(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bulk_requirements/releases',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Release Links
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createReleaseLinks(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bulk_requirements/link/releases',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove Release Links
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    removeReleaseLinks(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bulk_requirements/unlink/releases',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Mutual Interfaces For Requirements
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    getMutualInterfacesForRequirements(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bulk_requirements/interfaces',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Interface Links
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createInterfaceLinks(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bulk_requirements/link/interfaces',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove Interface Links
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    removeInterfaceLinks(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bulk_requirements/unlink/interfaces',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
