import beforeEnter from '@/router/modules/models/beforeEnter'

const routes = [
  {
    path: '/model/:modelId/specifications',
    name: 'all_specifications_table',
    component: () => import('@/views/Specifications/Specifications.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Specifications',
      auth: true,
      breadcrumb: [
        {
          text: 'Specifications',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/reviews',
    name: 'specification_reviews',
    component: () => import('@/views/Reviews/Reviews.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Reviews',
      auth: true,
      breadcrumb: [
        {
          text: 'Specifications',
          to: {
            name: 'all_specifications_table',
          },
        },
        {
          text: 'Reviews',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/specifications/import/ibm-doors-next',
    name: 'import_ibm_doors_module',
    component: () => import('@/views/Specifications/import/ImportIBMDOORsNgModule.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Import IBM DOORs Module',
      auth: true,
      breadcrumb: [
        {
          text: 'Specifications',
          to: {
            name: 'all_specifications_table',
          },
        },
        {
          text: 'Import IBM DOORs Next Module',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/specifications/export',
    name: 'export_specifications',
    component: () => import('@/views/Specifications/export/ExportSpecifications.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Export Specifications',
      auth: true,
      breadcrumb: [
        {
          text: 'Specifications',
          to: {
            name: 'all_specifications_table',
            params: {
              modelId: sessionStorage.getItem('kompozition-workspace'),
            },
          },
        },
        {
          text: 'Specification Export (PDF)',
          active: true,
        },
      ],
    },
  },
]

export default routes
